<template>
  <v-list density="compact">
    <v-list-item v-if="dossierLinks.length === 0">
      {{ $t("dossier.noLinks") }}
    </v-list-item>
    <v-list-item
      v-for="dossierLink in dossierLinksSorted"
      :key="dossierLink.id"
      :prepend-icon="mdiLink"
      :class="dossierLink.is_active ? '' : 'text-disabled'"
    >
      {{ $t("link.link") }} "{{ dossier.code_name }}"/{{ dossierLink.number }}
      <span
        style="
          font-size: 12px;
          font-style: italic;
          position: absolute;
          bottom: 0;
          left: 72px;
          color: grey;
        "
      >
        vom {{ readableDate(dossierLink.created_at) }}
      </span>
      <v-btn
        color="error"
        @click="
          async () => await dossierStore.disableDossierLink(dossierLink.url)
        "
        size="small"
        variant="text"
        class="ml-4"
        rounded="1"
        v-if="dossierLink.is_active"
        >{{ $t("deactivate") }}</v-btn
      ><span v-else>({{ $t("link.isInactive") }})</span>
    </v-list-item>
  </v-list>
</template>

<script setup lang="ts">
import { mdiLink } from "@mdi/js"
import { DossierOverview, useDossierStore } from "@/common/store/dossier"
import { computed } from "vue"
import { useSorted } from "@vueuse/core"
const dossierStore = useDossierStore()

export interface Props {
  dossier: DossierOverview
}
const props = defineProps<Props>()
defineEmits(["disableLink"])

const dossierLinks = computed(
  () =>
    (props.dossier && dossierStore.linksByDossierUrl.get(props.dossier.url)) ||
    [],
)
const dossierLinksSorted = useSorted(dossierLinks, (a, b) => {
  return -a.created_at.localeCompare(b.created_at)
})

function readableDate(date: string) {
  return new Date(date).toLocaleDateString("de-CH")
}
</script>
