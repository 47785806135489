<template>
  <v-dialog
    v-model="dialogVisible"
    :persistent="changesPending"
    :scrollable="true"
  >
    <v-card
      :loading="changesPending ? 'primary' : false"
      style="min-width: 30em"
      v-if="user"
    >
      <create-workstation-dialog
        :user="user"
        v-model="createWorkstationDialogOpen"
      />
      <delete-workstation-dialog v-model="deleteWorkstationUrl" />
      <reset-user-dialog
        v-model="resetUserDialogOpen"
        :user="user.url"
        width="auto"
      />
      <v-card-title class="mb-4"
        >{{ $t("workstation.workstationsOf") }}
        {{ user.display_name }}</v-card-title
      >
      <v-card-text>
        <v-table>
          <thead>
            <tr>
              <th class="shrink" />
              <th>Angelegt</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(workstation, i) in selectedUserWorkstations"
              :key="workstation.url"
              :class="{ 'highlight-row': hoverRow === i }"
            >
              <td v-if="workstation.url === authStore.session?.accessPoint.url">
                {{ $t("workstation.current") }}
              </td>
              <td
                class="text-right"
                v-else-if="
                  selectedUserWorkstations.length > 1 &&
                  (isSuperuser || isCurrentUser)
                "
              >
                <v-tooltip :text="$t('workstation.delete')">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      :icon="mdiTrashCan"
                      density="compact"
                      variant="plain"
                      color="error"
                      :disabled="changesPending"
                      @click="deleteWorkstationUrl = workstation.url"
                      @mouseover="hoverRow = i"
                      @mouseleave="hoverRow = null"
                      v-bind="props"
                    />
                  </template>
                </v-tooltip>
              </td>
              <td v-else />
              <td>
                <short-date :date="new Date(workstation.created_at)" />
              </td>
              <td>{{ workstation.name }}</td>
            </tr>
            <tr>
              <td colspan="4"></td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="isCurrentUser"
          :prepend-icon="mdiPlus"
          @click="createWorkstationDialogOpen = true"
          >{{ $t("workstation.add") }}</v-btn
        >
        <v-btn
          v-else-if="isSuperuser"
          :prepend-icon="mdiNuke"
          color="error"
          rounded="xs"
          @click="resetUserDialogOpen = true"
          >{{ $t("user.reset") }}</v-btn
        >
        <v-spacer />
        <v-btn
          color="default"
          @click="dialogVisible = false"
          :disabled="changesPending"
          rounded="xs"
        >
          {{ $t("closeWindow") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ApiUrl } from "@/common/lib/types"
import { computed, onMounted, ref } from "vue"
import { useUserStore } from "@/common/store/user"
import { useWorkstationStore, WorkstationDTO } from "@/common/store/workstation"
import { storeToRefs } from "pinia"
import ShortDate from "@/common/components/ShortDate.vue"
import { mdiNuke, mdiPlus, mdiTrashCan } from "@mdi/js"
import CreateWorkstationDialog from "@/components/CreateWorkstationDialog.vue"
import { useAuthStore } from "@/common/store/auth"
import DeleteWorkstationDialog from "@/components/DeleteWorkstationDialog.vue"
import ResetUserDialog from "@/components/ResetUserDialog.vue"

const userStore = useUserStore()
const authStore = useAuthStore()
const workstationStore = useWorkstationStore()

const { currentUser } = storeToRefs(useUserStore())

const emit = defineEmits(["update:modelValue"])
const props = defineProps<{ modelValue: ApiUrl | null }>()

const changesPending = ref(false)
const hoverRow = ref(null as number | null)
const createWorkstationDialogOpen = ref(false)
const resetUserDialogOpen = ref(false)
const deleteWorkstationUrl = ref(null as ApiUrl | null)

const user = computed(
  () =>
    (!!props.modelValue && userStore.requestUser(props.modelValue).value) ||
    null,
)
const { workstations } = storeToRefs(useWorkstationStore())
const isCurrentUser = computed(() => user.value?.url === currentUser.value?.url)
const isSuperuser = computed(() => currentUser.value?.type === "superuser")

onMounted(workstationStore.fetchWorkstations)

const selectedUserWorkstations = computed(() => {
  const retval = workstations.value.filter(
    (item) => item.user === props.modelValue,
  )
  retval.sort((a, b) => {
    if (a.url === authStore.session?.accessPoint.url) {
      return -1
    }
    if (b.url === authStore.session?.accessPoint.url) {
      return 1
    }
    return -a.created_at.localeCompare(b.created_at)
  })
  return retval
})

const dialogVisible = computed<boolean>({
  get() {
    return !!props.modelValue || false
  },
  set(v: boolean | null) {
    if (!v) {
      emit("update:modelValue", null)
      createWorkstationDialogOpen.value = false
    }
  },
})
</script>

<style scoped lang="scss"></style>
