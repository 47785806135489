<template>
  <v-dialog v-model="dialogVisible" :persistent="changesPending">
    <v-card
      :loading="changesPending ? 'primary' : false"
      style="min-width: 30em"
      v-if="workstation"
    >
      <v-card-title class="mb-4">{{ $t("workstation.delete") }}</v-card-title>
      <v-card-text
        >{{ $t("workstation.deleteThis") }} {{ workstation.name }}</v-card-text
      >
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="default"
          @click="dialogVisible = false"
          :disabled="changesPending"
          rounded="xs"
        >
          {{ $t("closeWindow") }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="changesPending"
          @click="deleteWorkstation(workstation)"
          rounded="xs"
        >
          {{ $t("workstation.delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ApiUrl } from "@/common/lib/types"
import { computed, ref } from "vue"
import { useWorkstationStore, WorkstationDTO } from "@/common/store/workstation"

const workstationStore = useWorkstationStore()

const emit = defineEmits(["update:modelValue"])
const props = defineProps<{ modelValue: ApiUrl | null }>()

const changesPending = ref(false)

const workstation = computed(
  () =>
    (!!props.modelValue &&
      workstationStore.requestWorkstation(props.modelValue).value) ||
    null,
)

const dialogVisible = computed<boolean>({
  get() {
    return !!props.modelValue || false
  },
  set(v: boolean | null) {
    if (!v) {
      emit("update:modelValue", null)
    }
  },
})

async function deleteWorkstation(workstation: WorkstationDTO) {
  try {
    changesPending.value = true
    await workstationStore.deleteWorkstation(workstation)
    dialogVisible.value = false
  } finally {
    changesPending.value = false
  }
}
</script>

<style scoped lang="scss"></style>
