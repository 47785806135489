<template>
  <v-dialog :persistent="newUserPending" v-model="dialogVisible">
    <v-form v-if="!createdUser" v-model="formValid">
      <v-card :loading="newUserPending" style="min-width: 30em">
        <v-card-title class="mb-4">{{ $t("user.create") }}</v-card-title>

        <v-card-text>
          <v-text-field
            v-model="displayName"
            color="primary"
            :label="$t('user.name')"
            variant="outlined"
            style="min-width: 200px"
            @keydown.enter="addUser"
            :rules="nameRules"
            class="mb-3"
            autofocus
          />
          <v-select
            v-model="type"
            color="primary"
            :label="$t('user.type')"
            :items="types"
            variant="outlined"
            style="min-width: 200px"
            @keydown.enter="addUser"
          >
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="$emit('update:modelValue', false)"
            :disabled="newUserPending"
            rounded="xs"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!formValid || newUserPending"
            @click="addUser"
            rounded="xs"
          >
            {{ $t("user.create") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <template v-else>
      <v-card :loading="newUserPending" style="min-width: 30em">
        <v-card-text>
          <p>
            {{ $t("user.createdDone") }} {{ $t("user.initialWorkstation") }}
            <v-tooltip
              v-model="createLinkCopied"
              location="bottom"
              :open-on-click="false"
              :open-on-hover="false"
            >
              <template v-slot:activator="{ props }">
                <v-text-field v-bind="props" v-model="readonlyBohUrl">
                  <template #append
                    ><v-btn
                      :icon="mdiContentCopy"
                      variant="plain"
                      @click="
                        writeClipboard(bohUrl(createdUser.initialWorkstation))
                      "
                  /></template>
                </v-text-field>
              </template>
              {{ $t("copiedToClipboard") }}
            </v-tooltip>
            {{ $t("workstation.openQr") }}
            <img :src="bohQrcode" alt="QR-Code" />
          </p>
          <p>{{ $t("user.linkOnce") }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="dialogVisible = false"
            :disabled="newUserPending"
            rounded="xs"
          >
            {{ $t("closeWindow") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script setup lang="ts">
import { computed, Ref, ref } from "vue"
import { useI18n } from "vue-i18n"
import { CreatedUser, UserType, useUserStore } from "@/common/store/user"
import { mdiContentCopy } from "@mdi/js"
import { bohUrl } from "@/common/lib/util"
import { useVModel, whenever } from "@vueuse/core"
import { useQRCode } from "@vueuse/integrations/useQRCode"
import { storeToRefs } from "pinia"

const { t } = useI18n()
const userStore = useUserStore()
const { users } = storeToRefs(useUserStore())

const newUserPending = ref(false)
const displayName = ref("")
const type: Ref<UserType> = ref("analyst")

const types = ["analyst", "profiler", "superuser"].map((r) => ({
  title: t(r),
  value: r,
}))

const props = defineProps<{ modelValue: boolean }>()
const emits = defineEmits(["update:modelValue"])

const formValid = ref(null as null | boolean)
const createdUser = ref(null as CreatedUser | null)
const createLinkCopied = ref(false)

const dialogVisible = useVModel(props, "modelValue", emits)
whenever(dialogVisible, () => {
  type.value = "analyst"
  displayName.value = ""
  createdUser.value = null
})

const nameRules = computed(() => {
  return [
    (val: string) => !!val || t("error.required"),
    (val: string) => {
      if (users.value.map((user) => user.display_name).includes(val)) {
        return t("error.userNameTaken")
      }
      return true
    },
  ]
})

// FIXME Reduce duplication with CreateWorkstationDialog
const readonlyBohUrl = computed({
  get() {
    return createdUser.value ? bohUrl(createdUser.value.initialWorkstation) : ""
  },
  set(v: string) {
    /* Nothing */
  },
})
const bohQrcode = useQRCode(readonlyBohUrl)

async function addUser() {
  newUserPending.value = true
  try {
    createdUser.value = await userStore.createUser(
      type.value,
      displayName.value,
    )
  } catch (e) {
    console.error(e) // todo display error in UI
  }
  newUserPending.value = false
}

function writeClipboard(value: any) {
  navigator.clipboard.writeText(value)
  createLinkCopied.value = true
  setTimeout(() => (createLinkCopied.value = false), 2000)
}
</script>
<style scoped lang="scss"></style>
