<template>
  <v-card>
    <v-toolbar class="pt-3">
      <div class="d-flex w-100 pl-2 justify-center align-center">
        <img src="@/common/assets/smartBlockIcon.svg" class="mr-2" />
        <div>
          <div
            style="
              font-size: 16px;
              font-weight: 700;
              border-bottom: 1px dashed grey;
              padding-bottom: 2px;
              margin-bottom: 2px;
            "
          >
            Über das dossier
          </div>
          <div style="font-size: 12px; font-weight: 400">
            Diese Informationen werden prominent zur Beschreibung des dossiers
            verwendet. Drücke Enter nach jedem Eintrag!
          </div>
        </div>
        <div class="mr-auto w-100 d-flex justify-end">
          <slot></slot>
        </div>
      </div>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-row class="topbar-inputs py-2 px-4 align-stretch justify-stretch">
      <v-col cols="4"
        ><v-text-field
          color="primary"
          base-color="primary"
          class="d-block h-100"
          v-if="model"
          :placeholder="$t('meta.title')"
          hide-details
          variant="filled"
          v-model="model!.title"
          @change="model.dirty = true"
        ></v-text-field
      ></v-col>
      <v-col cols="4">
        <v-combobox
          base-color="primary"
          color="primary"
          v-model="model!.person"
          chips
          closable
          hide-details
          :label="$t('meta.persons')"
          multiple
          variant="outlined"
        >
          <template #chip="{ item, index }">
            <v-chip
              :closable="!entitiesInUse[`person/${item.value}`]"
              @click:close="remove(index, 'person')"
              :append-icon="
                entitiesInUse[`person/${item.value}`] ? mdiCheck : undefined
              "
              >{{ item.value }}</v-chip
            >
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="4">
        <v-combobox
          color="primary"
          base-color="primary"
          class="d-block h-100"
          v-model="model!.company"
          chips
          hide-details
          closable
          :label="$t('meta.companies')"
          multiple
          variant="outlined"
        >
          <template #chip="{ item, index }">
            <v-chip
              :closable="!entitiesInUse[`company/${item.value}`]"
              @click:close="remove(index, 'company')"
              :append-icon="
                entitiesInUse[`company/${item.value}`] ? mdiCheck : undefined
              "
              >{{ item.value }}</v-chip
            >
          </template>
        </v-combobox></v-col
      >
    </v-row>
  </v-card>
</template>
<script setup lang="ts">
import {
  DossierMetaType,
  EntityDataEntityIdentifier,
} from "@/common/store/dossier/types"
import { onMounted, watch } from "vue"
import { mdiAccount, mdiCheck, mdiFactory } from "@mdi/js"

const model = defineModel<DossierMetaType>()
defineProps<{
  entitiesInUse: { [n: EntityDataEntityIdentifier]: boolean }
}>()

const remove = (index: number, type: "company" | "person") => {
  if (model.value) {
    if (type === "company") {
      model.value.company?.splice(index, 1)
    } else if (type === "person") {
      model.value.person?.splice(index, 1)
    }
  }
}
watch(
  // WARNING: Needs to be updated when model type changes
  () =>
    model.value && [
      model.value.title,
      [...(model.value.company || [])],
      [...(model.value.person || [])],
    ],
  () => {
    if (model.value) model.value.dirty = true
  },
  {
    deep: true,
  },
)
</script>
<style lang="scss">
.topbar-inputs {
  .v-input__control {
    height: 100% !important;
  }
}
</style>
