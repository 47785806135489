<template>
  <v-menu :close-on-content-click="false" @update:modelValue="linkOpened">
    <template v-slot:activator="{ props }">
      <slot name="activator" :props="props">
        <v-btn v-bind="props">{{ $t("link.create") }}</v-btn>
      </slot>
    </template>
    <v-card>
      <v-card-text>
        <div v-if="!link"><v-icon :icon="mdiClock" /></div>
        <div v-else>
          <v-tooltip
            v-model="createLinkCopied"
            location="bottom"
            :open-on-click="false"
            :open-on-hover="false"
          >
            <template v-slot:activator="{ props }">
              <span v-bind="props" class="text-mono">
                {{ fohUrl(link) }}
              </span>
            </template>
            {{ $t("copiedToClipboard") }}
          </v-tooltip>
          <v-btn
            :icon="mdiContentCopy"
            variant="plain"
            @click="writeClipboard(fohUrl(link))"
          />
          <v-switch
            v-if="!!link.pin"
            color="primary"
            :model-value="activatePin"
            :disabled="!link?.pin || activatePin || link.pin_state != 'primed'"
            :loading="activatePin && link.pin_state == 'primed'"
            @click="doActivatePin"
            class="no-opacity-disabled"
            :hint="$t('link.createInsecureHelp')"
            persistent-hint
          >
            <template v-slot:label>
              <span class="v-label-white">{{ $t('link.createInsecure') }}</span>
           </template>
          </v-switch>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">
import { mdiClock, mdiContentCopy } from "@mdi/js"
import { Ref, ref, watch } from "vue"
import {
  CreatedLink,
  DossierInfoDTO, LinkDTO,
  useDossierStore
} from "@/common/store/dossier"
import { fohUrl } from "@/common/lib/util"
import { APIReferenceable, EncryptedObj } from "@/common/lib/types"

const dossierStore = useDossierStore()

const createLinkCopied = ref(false)
const link = ref(null as CreatedLink | null)
const activatePin = ref(false)

export interface Props {
  dossier: APIReferenceable & EncryptedObj
}

const props = defineProps<Props>()

async function linkOpened(opened: boolean) {
  createLinkCopied.value = false
  link.value = null
  activatePin.value = false
  if (opened) {
    const dossier = dossierStore.byUrl.get(props.dossier.url)
    if (!dossier) {
      throw new Error("dossier is null")
    }
    link.value = await dossierStore.createDossierLink(dossier)
  }
}

async function doActivatePin() {
  if(!link.value || !link.value.pin || link.value.pin_state != 'primed' || activatePin.value) {
    return
  }
  activatePin.value = true
  link.value = {
    ...await dossierStore.setDossierLinkPinState(link as Ref<LinkDTO>, 'active'),
    password: link.value.password,
  }
}

function writeClipboard(value: any) {
  navigator.clipboard.writeText(value)
  createLinkCopied.value = true
  setTimeout(() => (createLinkCopied.value = false), 2000)
}
</script>
<style lang="css">
.no-opacity-disabled .v-selection-control--disabled {
  opacity: 1;
}
.no-opacity-disabled .v-selection-control--disabled .v-selection-control__wrapper {
  opacity: var(--v-disabled-opacity);
}
</style>
