<template>
  <v-table class="ma-0">
    <thead>
      <tr>
        <th class="shrink">
          <v-tooltip :text="$t('edit.rowAdd')">
            <template v-slot:activator="{ props }">
              <v-btn
                :icon="mdiPlus"
                density="compact"
                :disabled="disabled"
                @click="addRow('start')"
                v-bind="props"
              />
            </template>
          </v-tooltip>
        </th>
        <th
          class="text-left pa-0 ma-0"
          v-for="(title, i) in columnTitles"
          :key="i"
        >
          {{ title }}
        </th>
      </tr>
    </thead>
    <draggable
      tag="tbody"
      v-model="model!.entries"
      animation="200"
      item-key="id"
      drag-class="drag"
      ghost-class="ghost"
      class="list-group"
      handle=".handle"
    >
      <template #item="{ element, index }">
        <tr
          :key="index"
          class="list-group-item"
          :class="{
            'highlight-row': hoverRow === index,
          }"
          ref="rows"
        >
          <td class="shrink">
            <v-tooltip :text="$t('edit.rowDelete')">
              <template v-slot:activator="{ props }">
                <div class="d-flex handle cursor-move">
                  <v-btn
                    :icon="mdiDrag"
                    density="compact"
                    variant="plain"
                    :disabled="true"
                  />
                  <v-btn
                    :icon="mdiTrashCan"
                    density="compact"
                    variant="plain"
                    color="error"
                    :disabled="disabled"
                    @click="deleteRow(index)"
                    @mouseover="hoverRow = index"
                    @mouseleave="hoverRow = null"
                    v-bind="props"
                  />
                </div>
              </template>
            </v-tooltip>
          </td>
          <td :class="['pa-0', 'ma-0 ']" style="width: 210px">
            <v-combobox
              variant="outlined"
              density="compact"
              :hide-details="element.key"
              :disabled="disabled"
              v-model="element.key"
              class="pa-0 ma-1"
              :items="suggestedKeys"
              :rules="[
                (value: string) =>
                  !!value || $t('message.empty_fields_not_allowed'),
              ]"
            ></v-combobox>
          </td>
          <td :class="['pa-0', 'ma-0']">
            <date-input
              v-if="
                element.key == 'Geburtsdatum' || element.key == 'Gründungsdatum'
              "
              class="pa-0 ma-1"
              v-model="element.value"
              density="compact"
              hide-details
            />
            <v-text-field
              v-else
              class="pa-0 ma-1"
              v-model="element.value"
              density="compact"
              hide-details
              :disabled="disabled"
            />
          </td>
          <td :class="['pa-0', 'ma-0']">
            <v-text-field
              v-if="element.source"
              class="pa-0 ma-1"
              v-model="element.source"
              density="compact"
              hide-details
              :disabled="disabled"
            />
          </td>
        </tr>
      </template>
    </draggable>
    <tfoot>
      <tr>
        <td colspan="2" class="shrink">
          <div class="d-flex">
            <v-tooltip :text="$t('edit.rowAdd')">
              <template v-slot:activator="{ props }">
                <v-btn
                  :icon="mdiPlus"
                  size="large"
                  density="compact"
                  :disabled="disabled"
                  @click="addRow()"
                  v-bind="props"
                />
              </template>
            </v-tooltip>
            <v-tooltip :text="$t('edit.rowAdd')">
              <template v-slot:activator="{ props }">
                <v-btn
                  icon
                  size="large"
                  density="compact"
                  class="mx-3 font-weight-bold"
                  :disabled="disabled"
                  @click="addRow('end', 5)"
                  v-bind="props"
                  text="+5"
                />
              </template>
            </v-tooltip>
            <v-tooltip :text="$t('edit.rowAdd')">
              <template v-slot:activator="{ props }">
                <v-btn
                  icon
                  size="large"
                  density="compact"
                  class="font-weight-bold"
                  :disabled="disabled"
                  @click="addRow('end', 10)"
                  v-bind="props"
                  text="+10"
                />
              </template>
            </v-tooltip>
          </div>
        </td>
      </tr>
    </tfoot>
  </v-table>
</template>

<script setup lang="ts">
import DateInput from "@/components/DateInput.vue"
import {
  nextTick,
  ref,
  Ref,
  defineComponent,
  watchEffect,
  watch,
  computed,
  onUnmounted,
} from "vue"
import {
  EntityDataEntityTypes,
  EntityDataRow,
  EntityDataType,
} from "@/common/store/dossier"
import { mdiPlus, mdiTrashCan, mdiDrag } from "@mdi/js"
import draggable from "vuedraggable"
import { ENTITY_DATA_KEYS } from "@/common/constants"

defineComponent({
  draggable,
})

const props = withDefaults(
  defineProps<{
    disabled?: boolean
  }>(),
  { disabled: false },
)
const emit = defineEmits(["inUse", "notInUse", "update:modelValue"])
const hoverRow: Ref<string | number | null> = ref(null)

const model = defineModel<EntityDataType>()

const rows = ref()

const columnTitles = computed(() => {
  if (model.value?.entries.some((row) => !!row.source))
    return ["Titel", "Inhalt", "Quelle"]
  return ["Titel", "Inhalt"]
})

const suggestedKeys = computed(() => {
  if (!model.value?.entity) {
    return []
  }
  const entityType = model.value.entity.split("/")[0] as EntityDataEntityTypes
  return ENTITY_DATA_KEYS[entityType] ?? []
})

onUnmounted(() => {
  emit("notInUse")
})

watchEffect(() => {
  if (model.value?.entries.some((row) => !!row.value || !!row.source)) {
    emit("inUse")
  }
})

async function addRow(placement = "end", numberOfRows: 1 | 5 | 10 = 1) {
  if (typeof numberOfRows !== "number" || numberOfRows < 1) {
    throw new Error("Invalid Row Number")
  }
  let inputs: EntityDataRow = { key: "", value: "" }

  const newRows = Array.from({ length: numberOfRows }, () => ({
    ...(inputs as EntityDataRow),
  }))

  const firstFocusElement = model.value?.entries.length || 0

  placement === "start"
    ? model.value?.entries.unshift(...newRows)
    : model.value?.entries.push(...newRows)

  await nextTick()
  if (rows.value) {
    const tbody = rows.value?.parentNode
    const selectedRow = tbody?.children[firstFocusElement]
    const selectedElement =
      placement === "start" ? tbody.firstChild : selectedRow
    selectedElement.querySelector("input").focus()
  }
}

function deleteRow(i: any) {
  const tmp = [...model.value!.entries]
  tmp.splice(i, 1)
  if (model.value) {
    model.value.entries = tmp
  }
}

watch(
  model,
  (newData) => {
    if (
      newData &&
      newData.entries &&
      newData.entries.every((element) => element.key)
    ) {
      emit("update:modelValue", newData)
    }
  },
  {
    deep: true,
  },
)
</script>
<style lang="scss">
.drag {
  background: #c8ebfb;
  opacity: 1 !important;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
