<template>
  <div class="pa-2 widget">
    <textarea v-model="modelValue" class="w-100 code-textarea"></textarea>
  </div>
</template>

<script setup lang="ts">
const modelValue = defineModel<string>()
</script>
<style lang="scss" scoped>
.widget {
  border-top: 1px solid #d5d5d5;
}
.code-textarea {
  padding: 1em;
  margin: 0.5em 0;
  height: fit-content;
  min-height: 150px;
}
</style>
