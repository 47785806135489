<template>
  <div>
    <Teleport to="#app-bar-actions">
      <v-btn
        :prepend-icon="mdiFilePlus"
        variant="outlined"
        rounded="xs"
        v-if="canCreateDossier"
      >
        <span class="hidden-sm-and-down">{{ $t("dossier.create") }}</span>
        <create-dossier-dialog
          v-model="showDossierCreateDialog"
          activator="parent"
          width="auto"
        />
      </v-btn>
    </Teleport>
    <v-data-table-virtual
      class="dossier-select-table"
      :headers="headers"
      :items="dossierTableListWithMeta"
      @click:row="onRowClick"
      item-key="code_name"
    >
      <template v-slot:[`item.lastChange`]="{ item }">
        <short-date
          v-if="item.lastChange"
          location="bottom"
          :date="item.lastChange!"
          :short-style="{ dateStyle: 'medium', timeStyle: 'medium' }"
        />
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <short-date
          v-if="item.created_at"
          location="bottom"
          :date="item.created_at"
        />
      </template>
    </v-data-table-virtual>
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, onMounted, ref, watch } from "vue"
import { mdiFilePlus } from "@mdi/js"
import { asyncComputed } from "@vueuse/core"
import { useDossierStore, DossierOverview } from "@/common/store/dossier"
import { storeToRefs } from "pinia"
import { useUserStore } from "@/common/store/user"
import CreateDossierDialog from "@/components/CreateDossierDialog.vue"
import { useI18n } from "vue-i18n"
import ShortDate from "@/common/components/ShortDate.vue"
import { useRouter } from "vue-router"
import { ApiUrl } from "@/common/lib/types"
const { t } = useI18n()
const dossierStore = useDossierStore()
const { currentUser } = storeToRefs(useUserStore())
onMounted(dossierStore.fetchDossiers)
const headers = [
  {
    title: "Dossier",
    key: "code_name",
  },
  { title: t("meta.title"), key: "meta.title" },

  { title: t("dossier.level"), key: "level" },
  { title: t("dossier.createdAt"), key: "created_at" },
  { title: t("profiler"), key: "usersByRole.profiler.display_name" },
  { title: t("analyst"), key: "usersByRole.analyst.display_name" },
  { title: t("dossier.lastChange"), key: "lastChange" },
  { title: "", key: "action" },
]

const router = useRouter()

function onRowClick(item: any, row: any) {
  router.push({
    name: "dossier",
    params: { dossierId: row.item.id },
  })
}

const dossiersTableList = computed<DossierOverview[]>(() => {
  const data: DossierOverview[] = []
  for (const dossier of dossierStore.dossiersSorted) {
    const dossierDetails = dossierStore.getDossierOverview(
      dossier.url,
    ) as Readonly<Ref<DossierOverview>>
    if (!dossierDetails.value) continue
    data.push(dossierDetails.value)
  }
  return data
})

const dossierMetaList = computed(() => {
  const dossierList = dossiersTableList.value
  const result = {} as Record<ApiUrl, { title: string }>
  for (const dossierOverview of dossierList) {
    if (dossierOverview.meta && dossierOverview.meta.value?.title) {
      result[dossierOverview.url] = dossierOverview.meta.value
    }
  }
  return result
})

const dossierTableListWithMeta = computed(() => {
  return dossiersTableList.value.map((dossierOverview) => ({
    ...dossierOverview,
    meta:
      dossierOverview.url in dossierMetaList.value
        ? dossierMetaList.value[dossierOverview.url]
        : undefined,
  }))
})

const canCreateDossier = asyncComputed(async () => {
  return (
    currentUser.value &&
    ["profiler", "superuser"].includes(currentUser.value.type)
  )
}, false)

const showDossierCreateDialog = ref(false)
</script>

<style lang="scss">
.v-data-table.dossier-select-table table > thead > tr > th,
.v-data-table.dossier-select-table table tbody > tr > th {
  border-bottom: 3px solid #6e6e6e !important;
}
</style>
