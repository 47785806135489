import { APIReferenceable, ApiUrl } from "@/common/lib/types"
import { defineStore } from "pinia"
import { makeObjectStore } from "@/common/store/_helper"

export type CredentialType = "publicKey"
export type CredentialState = "active" | "disabled"
export type CredentialDTO = APIReferenceable & {
  id: string
  name: string
  created_at: string
  type: CredentialType
  state: CredentialState
  entities: ApiUrl[]
  last_seen: string
  data: any
  current_sign_count: number
}

export const useCredentialStore = defineStore("credential", () => {
  const {
    objects: credentials,
    byUrl,
    request: requestCredential,
    fetchAll: fetchCredentials,
  } = makeObjectStore<CredentialDTO>("credential/")

  return {
    credentials,
    requestCredential,
    fetchCredentials,
  }
})
