<template>
  <Editor v-bind="$attrs" :init="init" v-model="editorValue" />
</template>
<script setup lang="ts">
import { reactive, ref, watch } from "vue"
import Editor from "@tinymce/tinymce-vue"

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({ content: "" }),
  },
  plugins: {
    type: [String, Array],
    default: "quickbars table link autolink lists",
  },
  toolbar: {
    type: [String, Array],
    default:
      " bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify|bullist numlist |outdent indent blockquote | undo redo | link | axupimgs | removeformat | table | emoticons",
  },
  height: {
    type: Number,
    default: 500,
  },
})

const editorValue = ref(props.modelValue.content)

const emit = defineEmits(["update:modelValue"])

watch(editorValue, (newValue) => {
  emit("update:modelValue", {
    content: newValue,
  })
})

const init = reactive({
  language: "de",
  height: props.height,
  menubar: false,
  content_css: false,
  plugins: props.plugins,
  toolbar: props.toolbar,
  branding: false,
  default_link_target: "_blank",
  body_class: "rich-content",
})
</script>

<style scoped>
.logo {
  display: block;
  margin: 0 auto 2rem;
}

@media (min-width: 1024px) {
  #sample {
    display: flex;
    flex-direction: column;
    place-items: center;
    width: 1000px;
  }
}
</style>

<style lang="scss">
.rich-content {
  a {
    color: rgb(var(--v-theme-primary)) !important;
    &:visited {
      color: rgb(var(--v-theme-primary)) !important;
    }
  }
}
</style>
