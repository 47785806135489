<template>
  <v-dialog :persistent="resetUserPending" v-model="dialogVisible">
    <v-form v-if="!createdUser">
      <v-card :loading="resetUserPending" style="min-width: 30em" v-if="user">
        <v-card-title class="mb-4">{{ $t("user.resetQuestion") }}</v-card-title>

        <v-card-text>
          <p>{{ $t("user.user") }}: {{ user.display_name }}</p>
          <p>{{ $t("user.resetConfirm") }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="$emit('update:modelValue', false)"
            :disabled="resetUserPending"
            rounded="xs"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="resetUserPending"
            @click="resetUser"
            rounded="xs"
          >
            {{ $t("user.reset") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <template v-else-if="createdUser">
      <v-card :loading="resetUserPending" style="min-width: 30em">
        <v-card-text>
          <p>
            {{ $t("user.resetDone") }} {{ $t("user.initialWorkstation") }}
            <v-tooltip
              v-model="createLinkCopied"
              location="bottom"
              :open-on-click="false"
              :open-on-hover="false"
            >
              <template v-slot:activator="{ props }">
                <v-text-field v-bind="props" v-model="readonlyBohUrl">
                  <template #append
                    ><v-btn
                      :icon="mdiContentCopy"
                      variant="plain"
                      @click="
                        writeClipboard(bohUrl(createdUser.initialWorkstation))
                      "
                  /></template>
                </v-text-field>
              </template>
              {{ $t("copiedToClipboard") }}
            </v-tooltip>
            {{ $t("workstation.openQr") }}
            <img :src="bohQrcode" alt="QR-Code" />
          </p>
          <p>{{ $t("user.linkOnce") }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="dialogVisible = false"
            :disabled="resetUserPending"
            rounded="xs"
          >
            {{ $t("closeWindow") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script setup lang="ts">
import { computed, ref } from "vue"
import { CreatedUser, useUserStore } from "@/common/store/user"
import { mdiContentCopy } from "@mdi/js"
import { bohUrl } from "@/common/lib/util"
import { useVModel, whenever } from "@vueuse/core"
import { useQRCode } from "@vueuse/integrations/useQRCode"
import { ApiUrl } from "@/common/lib/types"

const userStore = useUserStore()

const resetUserPending = ref(false)

const props = defineProps<{ modelValue: boolean; user: ApiUrl }>()
const emits = defineEmits(["update:modelValue"])

const user = computed(
  () => (!!props.user && userStore.requestUser(props.user).value) || null,
)

const createdUser = ref(null as CreatedUser | null)
const createLinkCopied = ref(false)

const dialogVisible = useVModel(props, "modelValue", emits)
whenever(dialogVisible, () => {
  createdUser.value = null
})

// FIXME Reduce duplication with CreateWorkstationDialog
const readonlyBohUrl = computed({
  get() {
    return createdUser.value ? bohUrl(createdUser.value.initialWorkstation) : ""
  },
  set(v: string) {
    /* Nothing */
  },
})
const bohQrcode = useQRCode(readonlyBohUrl)

async function resetUser() {
  resetUserPending.value = true
  try {
    createdUser.value = await userStore.resetUser(user.value!.url)
  } catch (e) {
    console.error(e) // todo display error in UI
  }
  resetUserPending.value = false
}

function writeClipboard(value: any) {
  navigator.clipboard.writeText(value)
  createLinkCopied.value = true
  setTimeout(() => (createLinkCopied.value = false), 2000)
}
</script>
<style scoped lang="scss"></style>
