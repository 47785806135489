<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia"
import { useAuthStore } from "@/common/store/auth"

const { pinState } = storeToRefs(useAuthStore())
pinState.value = "disabled"
</script>
