<template>
  <div class="pa-2 d-flex flex-wrap" style="overflow: auto">
    <v-card class="ma-4 flex-0-0" v-if="companies.length > 1">
      <v-toolbar flat density="compact"
        ><template #title
          ><span class="text-subtitle-2">Unternehmen</span></template
        >
      </v-toolbar>
      <v-table v-if="companies.length > 1" density="compact">
        <thead>
          <tr>
            <th></th>
            <th
              v-for="otherCompany in companies.slice().reverse().slice(0, -1)"
              :key="otherCompany"
            >
              {{ otherCompany }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="company in companies.slice(0, -1)" :key="company">
            <td>{{ company }}</td>
            <td
              v-for="otherCompany in companies
                .slice(companies.indexOf(company) + 1)
                .reverse()"
              :key="otherCompany"
            >
              <v-checkbox
                hide-details
                density="compact"
                color="primary"
                v-if="company !== otherCompany"
                v-model="model.company_company[company][otherCompany]"
              />
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
    <v-divider />
    <v-card
      class="ma-4 w-100"
      v-if="companies.length > 0 && persons.length > 0"
    >
      <v-toolbar flat density="compact">
        <template #title
          ><span class="text-subtitle-2">Unternehmen / Personen</span></template
        ></v-toolbar
      >
      <v-table
        class="pa-2"
        v-if="persons.length && companies.length"
        density="compact"
      >
        <thead>
          <tr>
            <th></th>
            <th v-for="(person, index) in persons" :key="index">
              {{ person }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(company, companyIndex) in companies" :key="companyIndex">
            <th>{{ company }}</th>
            <td
              class="pa-2"
              v-for="(person, personIndex) in persons"
              :key="companyIndex + personIndex"
            >
              <v-combobox
                style="width: 180px"
                clearable
                hide-details
                density="compact"
                placeholder="keine"
                :items="PersonCompanyRoles"
                v-model="model.person_company[person][company]"
              ></v-combobox>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
    <v-divider />

    <v-card class="ma-4 flex-0-0" v-if="persons.length > 1">
      <v-toolbar flat density="compact">
        <template #title
          ><span class="text-subtitle-2">Personen</span></template
        >
      </v-toolbar>
      <v-table v-if="persons.length > 1" density="compact">
        <thead>
          <tr>
            <th></th>
            <th
              v-for="otherPerson in persons.slice().reverse().slice(0, -1)"
              :key="otherPerson"
            >
              {{ otherPerson }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="person in persons.slice(0, -1)" :key="person">
            <td>{{ person }}</td>
            <td
              v-for="otherPerson in persons
                .slice(persons.indexOf(person) + 1)
                .reverse()"
              :key="otherPerson"
            >
              <v-checkbox
                hide-details
                density="compact"
                color="primary"
                v-if="person !== otherPerson"
                v-model="model.person_person[person][otherPerson]"
              />
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
    <v-card class="ma-4 v-col-12">
      <v-toolbar flat density="compact">
        <template #title
          ><span class="text-subtitle-2"
            >Layoutinformationen (leer lassen für Autolayout)</span
          ></template
        >
      </v-toolbar>
      <v-card-text>
        <v-textarea v-model="layoutJson" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import {
  EntityDataEntityIdentifier,
  NetworkAnalysis,
  PersonCompanyRoles,
} from "@/common/store/dossier"

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    id: string
    entities: Set<EntityDataEntityIdentifier>
  }>(),
  { disabled: false },
)

const model = defineModel<NetworkAnalysis>({
  required: true,
})

const emit = defineEmits(["update:modelValue"])

const layoutJson = ref(
  model.value.layout ? JSON.stringify(model.value.layout) : "",
)
watch(layoutJson, (layout) => {
  if (layout) {
    try {
      model.value.layout = JSON.parse(layout)
    } catch (e) {
      console.warn("Invalid JSON string:", layout, e)
    }
  }
})
const persons = computed(() =>
  Array.from(props.entities)
    .filter((i) => i.startsWith("person/"))
    .map((i) => i.split("/").slice(1).join("/")),
)
const companies = computed(() =>
  Array.from(props.entities)
    .filter((i) => i.startsWith("company/"))
    .map((i) => i.split("/").slice(1).join("/")),
)

watch(
  persons,
  (persons) => {
    for (const person of persons) {
      if (!(person in model.value.person_person)) {
        model.value.person_person[person] = {}
      }
      if (!(person in model.value.person_company)) {
        model.value.person_company[person] = {}
      }
    }
  },
  {
    immediate: true,
  },
)

watch(
  companies,
  (companies) => {
    for (const company of companies) {
      if (!(company in model.value.company_company)) {
        model.value.company_company[company] = {}
      }
    }
  },
  {
    immediate: true,
  },
)

watch(
  model,
  (newData) => {
    emit("update:modelValue", newData)
  },
  {
    deep: true,
  },
)

watch(
  props.entities,
  () => {
    model.value.layout = undefined
    layoutJson.value = ""
  },
  { deep: true },
)
</script>
