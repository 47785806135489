<template>
  <tr>
    <td>
      <span v-if="user.url === currentUser?.url">{{ $t("currentUser") }}</span>
    </td>
    <td>
      <v-text-field
        v-if="canChangeUser"
        color="primary"
        v-model="userName"
        density="compact"
        hide-details
        variant="underlined"
        :loading="changeInProgress"
      >
        <template #append-inner
          ><v-icon :icon="mdiCheck" v-if="nameChanged" color="success" /><span
            style="width: 48px"
            v-else
        /></template> </v-text-field
      ><span v-else>{{ user.display_name }}</span>
    </td>
    <td>{{ $t(user.type) }}</td>
    <td>
      <v-btn
        v-if="user.url === currentUser?.url || canChangeUser"
        rounded
        density="comfortable"
        @click="$emit('openWorkstationManager', user.url)"
        >{{ $t("workstation.workstation", 2) }}</v-btn
      >
    </td>
    <td>
      <v-btn
        v-if="user.url === currentUser?.url || canChangeUser"
        rounded
        density="comfortable"
        @click="$emit('openCredentialManager', user.url)"
        >{{ $t("credential.credential", 2) }}</v-btn
      >
    </td>
    <!--<td>
        <v-btn rounded="xs" density="comfortable">
          {{ $t("dossier.dossier", 2) }}
        </v-btn>
      </td> -->
  </tr>
</template>
<script setup lang="ts">
import { User, useUserStore } from "@/common/store/user"
import { asyncComputed, refAutoReset, useDebounceFn } from "@vueuse/core"
import { storeToRefs } from "pinia"
import { ref, watch } from "vue"
import { mdiCheck } from "@mdi/js"

const userStore = useUserStore()
const { currentUser } = storeToRefs(useUserStore())

const changeInProgress = ref(false)
const nameChanged = refAutoReset(false, 3000)

const props = defineProps<{ user: User }>()
const emits = defineEmits(["openWorkstationManager", "openCredentialManager"])

const canChangeUser = asyncComputed(async () => {
  return currentUser.value && ["superuser"].includes(currentUser.value.type)
})

const userName = ref(props.user.display_name)
const changeNameDebounced = useDebounceFn(changeUserName, 500, {
  maxWait: 1500,
})

watch(userName, (newValue: string) => {
  nameChanged.value = false
  changeNameDebounced()
})

async function changeUserName() {
  changeInProgress.value = true
  try {
    await userStore.changeUser(props.user.url, { display_name: userName.value })
    nameChanged.value = true
  } finally {
    changeInProgress.value = false
  }
}
</script>
