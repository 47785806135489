<template>
  <v-table class="ma-0">
    <thead v-if="!barchartMode">
      <tr>
        <th class="shrink">
          <v-tooltip :text="$t('edit.rowAdd')">
            <template v-slot:activator="{ props }">
              <v-btn
                :icon="mdiPlus"
                density="compact"
                :disabled="disabled"
                @click="addRow('start')"
                v-bind="props"
              />
            </template>
          </v-tooltip>
        </th>
        <th
          class="text-left pa-0 ma-0"
          v-for="(title, i) in columnTitles"
          :key="i"
        >
          {{ title }}
        </th>
      </tr>
    </thead>

    <draggable
      tag="tbody"
      v-model="modelValue"
      animation="200"
      item-key="id"
      drag-class="drag"
      ghost-class="ghost"
      class="list-group"
      handle=".handle"
    >
      <template #item="{ element, index }">
        <tr
          :key="index"
          class="list-group-item"
          :class="{
            'highlight-row': hoverRow === index,
            'd-flex flex-column': barchartMode,
          }"
          ref="rows"
        >
          <td class="shrink" v-if="!barchartMode">
            <v-tooltip :text="$t('edit.rowDelete')">
              <template v-slot:activator="{ props }">
                <div class="d-flex handle cursor-move">
                  <v-btn
                    :icon="mdiDrag"
                    density="compact"
                    variant="plain"
                    :disabled="true"
                  />
                  <v-btn
                    :icon="mdiTrashCan"
                    density="compact"
                    variant="plain"
                    color="error"
                    :disabled="disabled"
                    @click="deleteRow(index)"
                    @mouseover="hoverRow = index"
                    @mouseleave="hoverRow = null"
                    v-bind="props"
                  />
                </div>
              </template>
            </v-tooltip>
          </td>
          <td
            v-for="(_, ci) in element"
            :key="ci + index"
            :class="[
              'pa-0',
              'ma-0',
              chronologyMode && (ci === 0 || ci === 3) ? 'shrink' : undefined,
            ]"
            :style="barchartMode && ci === 1 ? 'width: 50%' : undefined"
          >
            <template v-if="!chronologyMode">
              <v-radio-group
                inline
                class="pa-0 ma-1"
                v-if="barchartMode && ci === 1"
                v-model="element[ci]"
                :disabled="disabled"
                @input="updateCell(index, ci, $event.target.value)"
              >
                <v-radio label="0" value="0"></v-radio>
                <v-radio label="1" value="1"></v-radio>
                <v-radio label="2" value="2"></v-radio>
                <v-radio label="3" value="3"></v-radio>
              </v-radio-group>
              <v-text-field
                v-else-if="!chronologyMode || ci !== 0"
                class="pa-0 ma-1"
                v-model="element[ci]"
                density="compact"
                hide-details
                :disabled="disabled"
                @input="updateCell(index, ci, $event.target.value)"
              />
            </template>
            <template v-if="chronologyMode">
              <v-text-field
                v-if="ci !== 0 && ci !== 3"
                class="pa-0 ma-1"
                v-model="element[ci]"
                density="compact"
                hide-details
                :disabled="disabled"
                @input="updateCell(index, ci, $event.target.value)"
              />
              <v-checkbox
                color="primary"
                style="height: 50px"
                @update:modelValue="updateCell(index, ci, element[ci])"
                class="px-1"
                v-model="element[ci]"
                v-else-if="ci == 3"
              >
              </v-checkbox>
              <date-input
                v-else
                class="pa-0 ma-1"
                v-model="element[ci]"
                style="width: 8em"
                density="compact"
                variant="solo"
                :disabled="disabled"
                hide-details="auto"
                @update:model-value="updateCell(index, ci, $event)"
                :id="id"
              />
            </template>
          </td>
        </tr>
      </template>
    </draggable>
    <tfoot>
      <tr v-if="!barchartMode">
        <td colspan="2" class="shrink">
          <div class="d-flex">
            <v-tooltip :text="$t('edit.rowAdd')">
              <template v-slot:activator="{ props }">
                <v-btn
                  :icon="mdiPlus"
                  size="large"
                  density="compact"
                  :disabled="disabled"
                  @click="addRow()"
                  v-bind="props"
                />
              </template>
            </v-tooltip>
            <v-tooltip :text="$t('edit.rowAdd')">
              <template v-slot:activator="{ props }">
                <v-btn
                  icon
                  size="large"
                  density="compact"
                  class="mx-3 font-weight-bold"
                  :disabled="disabled"
                  @click="addRow('end', 5)"
                  v-bind="props"
                  text="+5"
                />
              </template>
            </v-tooltip>
            <v-tooltip :text="$t('edit.rowAdd')">
              <template v-slot:activator="{ props }">
                <v-btn
                  icon
                  size="large"
                  density="compact"
                  class="font-weight-bold"
                  :disabled="disabled"
                  @click="addRow('end', 10)"
                  v-bind="props"
                  text="+10"
                />
              </template>
            </v-tooltip>
          </div>
        </td>
      </tr>
    </tfoot>
  </v-table>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, Ref, defineComponent } from "vue"
import { ChronologyType, KeyValueType } from "@/common/store/dossier"
import { mdiPlus, mdiTrashCan, mdiDrag } from "@mdi/js"
import DateInput from "@/components/DateInput.vue"
import draggable from "vuedraggable"

defineComponent({
  draggable,
})

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    chronologyMode?: boolean
    barchartMode?: boolean
    id: string
  }>(),
  { disabled: false, chronologyMode: false },
)
const hoverRow: Ref<string | number | null> = ref(null)

const modelValue = defineModel<KeyValueType[] | ChronologyType[]>()

// updateInternalTableData if chronologyMode
// FIXME This is an inline data format conversion
if (props.chronologyMode) {
  if (
    modelValue.value!.some((row) => Array.isArray(row) && row[3] === undefined)
  ) {
    modelValue.value = modelValue.value!.map((row) => {
      if (Array.isArray(row) && row[3] === undefined) {
        return [row[0], row[1], row[2], false]
      }
      return row
    })
  }
}

const rows = ref()

const columnTitles = computed(() =>
  props.chronologyMode
    ? ["Datum", "Ereignis", "Quelle", "Wichtig"]
    : modelValue.value![0] && modelValue.value![0].length === 2
    ? ["Titel", "Inhalt"]
    : ["Titel", "Inhalt", "Quelle"],
)

const updateCell = (rowIndex: number, cellIndex: number, value: string) => {
  const newData = [...modelValue.value!]
  newData[rowIndex][cellIndex] = value
  modelValue.value = newData
}

async function addRow(placement = "end", numberOfRows: 1 | 5 | 10 = 1) {
  if (typeof numberOfRows !== "number" || numberOfRows < 1) {
    throw new Error("Invalid Row Number")
  }
  let inputs: KeyValueType | ChronologyType =
    modelValue.value![0] && modelValue.value![0].length === 2
      ? ["", ""]
      : props.chronologyMode
      ? ["", "", "", false]
      : ["", "", ""]

  const firstFocusElement = modelValue.value?.length || 0
  const newRows = Array.from(
    { length: numberOfRows },
    () => [...inputs] as KeyValueType,
  )
  placement === "start"
    ? modelValue.value?.unshift(...newRows)
    : modelValue.value?.push(...newRows)

  await nextTick()
  if (rows.value) {
    const tbody = rows.value?.parentNode
    const selectedRow = tbody?.children[firstFocusElement]
    const selectedElement =
      placement === "start" ? tbody.firstChild : selectedRow
    selectedElement.querySelector("input").focus()
  }
}

function deleteRow(i: any) {
  const tmp = [...modelValue.value!]
  tmp.splice(i, 1)
  modelValue.value = tmp
}
</script>
<style lang="scss">
.drag {
  background: #c8ebfb;
  opacity: 1 !important;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
