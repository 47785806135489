<template>
  <v-dialog :persistent="changesPending" v-model="dialogVisible">
    <v-card
      :loading="changesPending ? 'primary' : false"
      style="min-width: 30em"
    >
      <v-card-title class="mb-4">{{ $t("workstation.add") }}</v-card-title>
      <template v-if="!addedWorkstation">
        <v-card-text>
          <v-text-field
            v-model="workstationName"
            :hint="$t('workstation.name')"
            :persistent-hint="true"
            :placeholder="'Workstation ' + user.display_name"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="dialogVisible = false"
            :disabled="changesPending"
            rounded="xs"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="changesPending"
            @click="addWorkstation"
            rounded="xs"
          >
            {{ $t("workstation.add") }}
          </v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text>
          <p>
            {{ $t("workstation.created") }}
            <v-tooltip
              v-model="createLinkCopied"
              location="bottom"
              :open-on-click="false"
              :open-on-hover="false"
            >
              <template v-slot:activator="{ props }">
                <v-text-field v-bind="props" v-model="readonlyBohUrl">
                  <template #append
                    ><v-btn
                      :icon="mdiContentCopy"
                      variant="plain"
                      @click="writeClipboard(bohUrl(addedWorkstation))"
                  /></template>
                </v-text-field>
              </template>
              {{ $t("copiedToClipboard") }}
            </v-tooltip>
            {{ $t("workstation.openQr") }}
            <img :src="bohQrcode" alt="QR-Code" />
          </p>
          <p>{{ $t("workstation.linkOnce") }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="dialogVisible = false"
            :disabled="changesPending"
            rounded="xs"
          >
            {{ $t("closeWindow") }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { useVModel, whenever } from "@vueuse/core"
import { useQRCode } from "@vueuse/integrations/useQRCode"
import { User } from "@/common/store/user"
import {
  CreatedWorkstation,
  useWorkstationStore,
} from "@/common/store/workstation"
import { mdiContentCopy } from "@mdi/js"
import { bohUrl } from "@/common/lib/util"
import { b64decode } from "@/common/lib/encoding"
import { useCryptoStore } from "@/common/store/crypto"
import { useAuthStore } from "@/common/store/auth"

const emit = defineEmits(["update:modelValue"])
const props = defineProps<{ user: User; modelValue: boolean }>()

const cryptoStore = useCryptoStore()
const authStore = useAuthStore()
const workstationStore = useWorkstationStore()

const workstationName = ref("")

const changesPending = ref(false)
const createLinkCopied = ref(false)
const addedWorkstation = ref(null as CreatedWorkstation | null)

const dialogVisible = useVModel(props, "modelValue", emit)

whenever(dialogVisible, () => {
  workstationName.value = ""
  addedWorkstation.value = null
})

const readonlyBohUrl = computed({
  get() {
    return addedWorkstation.value ? bohUrl(addedWorkstation.value) : ""
  },
  set(v: string) {
    /* Nothing */
  },
})
const bohQrcode = useQRCode(readonlyBohUrl)

async function addWorkstation() {
  try {
    changesPending.value = true
    // FIXME This probably should be centralized (other instance is in unlock())
    const apData = cryptoStore.exportKeyBox!.decryptJson<{ box_key: string }>(
      b64decode(authStore.session!.accessPoint.private_data),
    )

    addedWorkstation.value = await workstationStore.createWorkstation(
      props.user,
      b64decode(apData.box_key),
      workstationName.value || undefined,
    )
  } finally {
    changesPending.value = false
  }
}

function writeClipboard(value: any) {
  navigator.clipboard.writeText(value)
  createLinkCopied.value = true
  setTimeout(() => (createLinkCopied.value = false), 2000)
}
</script>

<style scoped lang="scss"></style>
