<template>
  <v-table class="bc-table">
    <tbody>
      <tr
        :class="{ 'highlight-row': hoverRow === index }"
        v-for="(element, index) in internalTableData"
        :key="index"
        class="bc-table-tr d-flex flex-column list-group-item"
        ref="rows"
      >
        <td class="bc-table-td">
          <v-text-field
            v-model="element[0]"
            density="compact"
            hide-details
            :disabled="disabled"
            @input="updateCell(index, 0, $event.target.value)"
          />
        </td>
        <td class="bc-table-td">
          <v-radio-group
            inline
            v-model="element[1]"
            :disabled="disabled"
            @input="updateCell(index, 1, Number($event.target.value))"
          >
            <v-radio
              class="mr-2"
              v-for="(option, i) in element[2]"
              :value="option[0]"
              :key="i"
            >
              <template v-slot:label>
                <v-text-field
                  hide-details
                  v-model="option[1]"
                  density="compact"
                  :disabled="disabled"
                /> </template
            ></v-radio>
          </v-radio-group>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from "vue"
import { KpiBarChartType, BarChartOption } from "@/common/store/dossier"

const props = withDefaults(
  defineProps<{
    modelValue: KpiBarChartType[]
    disabled?: boolean
    id: string
  }>(),
  { disabled: false, chronologyMode: false },
)
const emit = defineEmits(["update:modelValue"])
const hoverRow: Ref<string | number | null> = ref(null)

const internalTableData = computed({
  get: () => {
    return props.modelValue.map((row) => {
      if (!row[2]) {
        row.push(defaultChoices.map((choice) => [...choice]))
        return row
      }
      return row
    })
  },
  set: (value) => emit("update:modelValue", value),
})
const rows = ref()

const defaultChoices: BarChartOption[] = [
  [0, "keine"],
  [1, "wenig"],
  [2, "viel"],
  [3, "sehr viel"],
]

const updateCell = (
  rowIndex: number,
  cellIndex: number,
  value: number | string,
) => {
  typeof value === "number" ? Number(value) : value

  const newData = [...internalTableData.value]
  newData[rowIndex][cellIndex] = value
  internalTableData.value = [...newData]
}
</script>

<style lang="scss">
.bc {
  &-table {
    &-tr {
    }
    &-td {
      height: auto !important;
      &:first-child {
        border-bottom: none !important;
        margin-top: 8px !important;
      }
      &:last-child {
        margin-bottom: 8px !important;
        padding-bottom: 8px !important;
      }
      .v-radio-group {
        box-shadow: 1px 1px 20px 7px #efefef;
        display: flex;
        padding: 8px;
      }
      input[type="text"] {
        min-width: 130px;
      }
    }
  }
}
</style>
