<template>
  <v-menu
    min-width="200px"
    rounded
    location="end"
    :open-on-hover="true"
    open-delay="0"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ props }">
      <v-btn icon v-bind="props">
        <v-avatar size="large" color="primary-darken-1">
          {{ initials }}
        </v-avatar>
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <div class="mx-auto text-center">
          <v-avatar color="primary-darken-1">
            {{ initials }}
          </v-avatar>
          <h3>{{ user.display_name }}</h3>
          <p class="text-caption mt-1">
            {{ user.type }}
          </p>
          <!--          <v-divider class="my-3"></v-divider>-->
          <!--          <v-btn-->
          <!--            rounded-->
          <!--            variant="text"-->
          <!--          >-->
          <!--            Edit Account-->
          <!--          </v-btn>-->
          <!--          <v-divider class="my-3"></v-divider>-->
          <!--          <v-btn-->
          <!--            rounded-->
          <!--            variant="text"-->
          <!--          >-->
          <!--            Disconnect-->
          <!--          </v-btn>-->
          <v-divider class="my-3" />
          <v-switch
            v-model="developerMode"
            :label="$t('pref.developerMode')"
            color="primary"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { User } from "@/common/store/user"
import { storeToRefs } from "pinia"
import { usePrefsStore } from "@/common/store/prefs"

const props = withDefaults(defineProps<{ user: User }>(), {})
const { developerMode } = storeToRefs(usePrefsStore())

const initials = computed(() => {
  if (props.user.display_name) {
    let parts = props.user.display_name.split(/\s+/)
    if (parts.length > 2) parts = [parts[0], parts[parts.length - 1]]
    return parts.map((n: string) => n[0].toUpperCase()).join("")
  }
  return "?"
})
</script>

<style scoped lang="scss"></style>
