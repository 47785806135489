<template>
  <v-card>
    <v-toolbar height="70" class="pt-0">
      <div style="width: 100%; margin-left: 8px">
        <div
          style="
            font-size: 16px;
            font-weight: 700;
            border-bottom: 1px dashed grey;
            padding-bottom: 2px;
            margin-bottom: 2px;
          "
        >
          Abschnitt hinzufügen
        </div>
        <div style="font-size: 12px; font-weight: 400">
          Blöcke werden standardmäßig am Ende hinzugefügt, durch das Auswahlmenü
          kann ein anderer Ort definiert werden.
        </div>
      </div>
    </v-toolbar>
    <v-card-text>
      <v-select
        v-model="selectedInsertPosition"
        :items="positionChoices"
        variant="underlined"
        density="compact"
        :disabled="!isEditing"
        hide-details
        return-object
      />
    </v-card-text>
    <v-card-actions>
      <div>
        <v-btn
          v-for="btn in addButtons"
          :key="btn"
          :disabled="!isEditing"
          @click="addBlock(btn)"
        >
          <v-icon :icon="mdiPlus"></v-icon>
          {{ btn }}
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts" setup>
import { onMounted, ref, computed, watchEffect } from "vue"
import { mdiPlus } from "@mdi/js"
import {
  ChapterType,
  DossierContents,
  DossierContentSection,
} from "@/common/store/dossier"

const props = withDefaults(
  defineProps<{
    contents: DossierContents
    disabled?: boolean
    isEditing?: boolean
  }>(),
  { isEditing: true },
)

const emit = defineEmits(["handle-insert"])

const addButtons = ref([
  "Text",
  // "2-spaltige Tabelle",
  // "3-spaltige Tabelle",
  // "Bildergalerie",
  // "Dokumente anhängen",
  //  "Featured Foto",
  //  "Kennzahlen",
  //  "Chronologie",
])

const flat = computed(() => props.contents?.rows.flat())

const positionChoices = computed(() => {
  const retval = [
    {
      title: "am Anfang einfügen",
      pos: null as [number, number] | null,
    },
  ]
  if (flat.value?.length) {
    retval.push(
      ...flat
        .value!.filter((item) =>
          ["Text", "RichText"].includes(item.content.type),
        )
        .map((item) => ({
          title: "nach " + item.content.meta.headline + " einfügen",
          pos: item.content.meta.pos,
          order: item.content.meta.order,
        })),
      {
        title: "am Ende einfügen",
        pos: flat.value[flat.value.length - 1].content.meta.pos,
      },
    )
  }
  return retval.map((item, i) => ({ ...item, value: i }))
})

const selectedInsertPosition: any = ref(
  positionChoices.value[positionChoices.value.length - 1],
)
watchEffect(() => {
  selectedInsertPosition.value =
    positionChoices.value[positionChoices.value.length - 1]
})

// TODO : Need Refactor
function addBlock(blockName: string, atEnd?: boolean) {
  const insertPosition = atEnd
    ? flat.value[flat.value.length - 1].content.meta.pos
    : selectedInsertPosition.value.pos
  const order = selectedInsertPosition.value.order
    ? selectedInsertPosition.value.order
    : 99

  const pos: [number, number] = insertPosition
    ? [insertPosition[0] + 1, 0]
    : [0, 0]

  let body = null as any
  let type = blockName
  if (blockName == "Text") {
    body = {
      content: "<p></p>",
    }
    type = "RichText"
  }
  if (blockName == "Kommentare") {
    body = []
    if (props.contents?.rows?.length) {
      for (let i = 0; i < props.contents.rows.length; i++) {
        body.push(["", ""])
      }
    }
    type = "CommentsBlock"
  }
  if (blockName == "2-spaltige Tabelle") {
    body = [["Neuer Eintrag", ""]]
    type = "KeyValueTable"
  }
  if (blockName == "3-spaltige Tabelle") {
    body = [["Neuer Eintrag", "", ""]]
    type = "KeyValueTable"
  }
  if (blockName == "Chronologie") {
    body = [["", "", ""]]
    type = "Chronology"
  }
  if (blockName == "Bildergalerie") {
    body = []
    type = "Gallery"
  }
  if (blockName == "Dokumente anhängen") {
    body = []
    type = "FileCollection"
  }
  if (blockName == "Featured Foto") {
    body = []
    type = "FeaturedPhoto"
  }
  if (blockName == "Kennzahlen") {
    body = [
      ["Social-Media Sichtbarkeit", ""],
      ["Intime Details", ""],
      ["Anzahl verbundener Kontakte", ""],
    ]
    type = "KpiBarChart"
  }
  if (blockName === "Presseberichte") {
    body = []
    type = "SavedWebsite"
  }

  const data: DossierContentSection = {
    content: {
      body: body as any,
      meta: {
        order: order,
        headline: blockName,
        pos,
        width: ["Featured Foto", "Chronologie", "Kennzahlen"].includes(
          blockName,
        )
          ? 6
          : 12,
      },
      type: type as ChapterType,
    },
    dirty: true,
  }

  emit("handle-insert", data)
}

onMounted(async () => {
  if (!flat.value?.find((x: any) => x.content.type == "FeaturedPhoto")) {
    addBlock("Featured Foto", true)
  }
  if (!flat.value?.find((x: any) => x.content.type == "Chronology")) {
    addBlock("Chronologie", true)
  }
  if (!flat.value?.find((x: any) => x.content.type == "CommentsBlock")) {
    addBlock("Kommentare", true)
  }
})
</script>
