<template>
  <v-dialog
    v-model="dialogVisible"
    :persistent="changesPending"
    :scrollable="true"
  >
    <v-card
      :loading="changesPending ? 'primary' : false"
      style="min-width: 30em"
      v-if="user"
    >
      <!--
      <register-passkey-dialog
        :user="user"
        v-model="registerPasskeyDialogOpen"
      />
      <delete-workstation-dialog v-model="deleteWorkstationUrl" />-->
      <v-card-title class="mb-4"
        >{{ $t("credential.credentialsOf") }}
        {{ user.display_name }}</v-card-title
      >
      <v-card-text>
        <v-table>
          <thead>
            <tr>
              <th class="shrink" />
              <th class="shrink" />
              <th>Name / Typ</th>
              <th>Angelegt</th>
              <th>Zuletzt benutzt</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(credential, i) in selectedUserCredentials"
              :key="credential.url"
              :class="{ 'highlight-row': hoverRow === i }"
            >
              <td v-if="credential.url === authStore.session?.credential">
                {{ $t("credential.current") }}
              </td>
              <td
                class="text-right"
                v-else-if="
                  selectedUserCredentials.length > 1 &&
                  (isSuperuser || isCurrentUser)
                "
              >
                <!-- <v-tooltip :text="$t('credential.delete')">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      :icon="mdiTrashCan"
                      density="compact"
                      variant="plain"
                      color="error"
                      :disabled="changesPending"
                      @click="deleteWorkstationUrl = workstation.url"
                      @mouseover="hoverRow = i"
                      @mouseleave="hoverRow = null"
                      v-bind="props"
                    />
                  </template>
                </v-tooltip> -->
              </td>
              <td v-else />
              <td>
                <v-img
                  v-if="displayInformationForCredentials[credential.url]"
                  :src="
                    displayInformationForCredentials[credential.url]?.icon_light
                  "
                  width="24px"
                />
                <v-icon
                  :icon="mdiMonitor"
                  v-else-if="
                    credential.data?.credential_device_type === 'single_device'
                  "
                  size="24px"
                />
                <v-icon
                  :icon="mdiMonitorMultiple"
                  v-else-if="
                    credential.data?.credential_device_type === 'multi_device'
                  "
                  size="24px"
                />
                <v-icon :icon="passkeyLogo" v-else size="24px" />
              </td>
              <td>
                <v-text-field
                  readonly
                  v-model="credential.name"
                  variant="underlined"
                  :placeholder="$t('credential.setName')"
                  hide-details
                  style="min-width: 20em"
                  color="primary"
                />
                <div v-if="displayInformationForCredentials[credential.url]">
                  {{ displayInformationForCredentials[credential.url]?.name }}
                </div>
              </td>
              <td>
                <short-date :date="new Date(credential.created_at)" />
              </td>
              <td>
                <short-date :date="new Date(credential.last_seen)" />
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="isCurrentUser && false"
          :prepend-icon="mdiPlus"
          @click="registerPasskeyDialogOpen = true"
          >{{ $t("credential.add") }}</v-btn
        >
        <v-spacer />
        <v-btn
          color="default"
          @click="dialogVisible = false"
          :disabled="changesPending"
          rounded="xs"
        >
          {{ $t("closeWindow") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ApiUrl } from "@/common/lib/types"
import { computed, onMounted, ref } from "vue"
import { useUserStore } from "@/common/store/user"
import { storeToRefs } from "pinia"
import ShortDate from "@/common/components/ShortDate.vue"
import { mdiMonitor, mdiMonitorMultiple, mdiPlus } from "@mdi/js"
import { useAuthStore } from "@/common/store/auth"
import { useCredentialStore } from "@/store/credential"
import { asyncComputed } from "@vueuse/core"
import { useApiStore } from "@/common/store/api"
import { passkeyLogo } from "@/common/lib/icons"

const apiStore = useApiStore()
const userStore = useUserStore()
const authStore = useAuthStore()
const credentialStore = useCredentialStore()

const { currentUser } = storeToRefs(useUserStore())

const emit = defineEmits(["update:modelValue"])
const props = defineProps<{ modelValue: ApiUrl | null }>()

const changesPending = ref(false)
const hoverRow = ref(null as number | null)

const user = computed(
  () =>
    (!!props.modelValue && userStore.requestUser(props.modelValue).value) ||
    null,
)
const { credentials } = storeToRefs(credentialStore)
const isCurrentUser = computed(() => user.value?.url === currentUser.value?.url)
const isSuperuser = computed(() => currentUser.value?.type === "superuser")
const registerPasskeyDialogOpen = ref(false)

onMounted(credentialStore.fetchCredentials)

const selectedUserCredentials = computed(() => {
  const retval = credentials.value.filter((item) =>
    item.entities.includes(props.modelValue!),
  )
  retval.sort((a, b) => {
    if (a.url === authStore.session?.accessPoint.url) {
      return -1
    }
    if (b.url === authStore.session?.accessPoint.url) {
      return 1
    }
    return -a.created_at.localeCompare(b.created_at)
  })
  return retval
})

type AuthenticatorDisplayInformation = {
  name: string
  icon_dark: string
  icon_light: string
}

const displayInformationForCredentials = asyncComputed(
  async () => {
    const aaguids = new Set(
      selectedUserCredentials.value
        .map((c) => c.data.aaguid)
        .filter((item) => !!item),
    )
    if (!aaguids.size) {
      return {}
    }
    const displayInformation = await apiStore.GET<
      Record<string, AuthenticatorDisplayInformation>
    >(
      "misc/authenticator_information/?aaguids=" +
        Array.from(aaguids).join(","),
    )
    return Object.fromEntries(
      selectedUserCredentials.value.map((entry) => {
        return [entry.url, displayInformation[entry.data?.aaguid] || null]
      }),
    )
  },
  {} as Record<ApiUrl, AuthenticatorDisplayInformation | null>,
)

const dialogVisible = computed<boolean>({
  get() {
    return !!props.modelValue || false
  },
  set(v: boolean | null) {
    if (!v) {
      emit("update:modelValue", null)
      //createWorkstationDialogOpen.value = false
    }
  },
})
</script>

<style scoped lang="scss"></style>
