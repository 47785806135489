<template>
  <v-table
    :class="{ draggedOnz: draggedOn }"
    @dragover.prevent="draggedOn = true"
    @dragend.prevent="draggedOn = false"
    @dragleave.prevent="draggedOn = false"
    @drop.prevent="onDrop"
  >
    <thead>
      <tr>
        <td class="shrink" colspan="3">
          <v-tooltip :text="$t('edit.fileAdd')">
            <template v-slot:activator="{ props }">
              <v-btn
                :icon="mdiPlus"
                density="compact"
                :disabled="disabled"
                @click="uploader.click()"
                v-bind="props"
              />
            </template>
          </v-tooltip>
          <input
            multiple
            ref="uploader"
            class="d-none"
            type="file"
            accept="*"
            @change="onInput"
          />
        </td>
        <td class="shrink" style="width: 50px"><span>pinned</span></td>
        <td class="shrink" style="width: 50px"><span>neu</span></td>
        <td class="shrink" style="width: 50px">
          <span>Analyse (intern)</span>
        </td>
      </tr>
      <tr>
        <td class="shrink" colspan="6">
          <v-tabs v-model="currentType">
            <v-tab size="x-small" value="all" key="all">All</v-tab>
            <v-tab
              v-for="(eType, key) in tabList"
              :disabled="!eType.enabled"
              size="x-small"
              :value="eType.key"
              :key="key"
              >{{ eType.value }}</v-tab
            >
          </v-tabs>
        </td>
      </tr>
    </thead>
    <draggable
      tag="tbody"
      v-model="model.entries"
      item-key="id"
      class="list-group"
      handle=".handle"
    >
      <template #item="{ element, index }">
        <edit-archive-entry
          v-show="shouldShow(element)"
          :model-value="element"
          :class="{
            'highlight-row': hoverRow === index,
          }"
          :entities="entities"
          @delete-row="deleteRow(index)"
          @delete-hover="hoverRow = index"
          @delete-leave="hoverRow = null"
          @make-featured="onMakeFeatured"
        />
      </template>
    </draggable>
    <tfoot>
      <tr>
        <td class="shrink">
          <v-tooltip :text="$t('edit.fileAdd')">
            <template v-slot:activator="{ props }">
              <v-btn
                :icon="mdiPlus"
                density="compact"
                :disabled="disabled"
                @click="uploader.click()"
                v-bind="props"
              />
            </template>
          </v-tooltip>
          <input
            multiple
            ref="uploader"
            class="d-none"
            type="file"
            accept="*"
            @change="onInput"
          />
        </td>
        <td colspan="3"></td>
      </tr>
    </tfoot>
  </v-table>
</template>

<script setup lang="ts">
import { computed, ref, Ref, watch } from "vue"
import {
  ArchiveEntry,
  ArchiveEntryType,
  ArchiveType,
  EntityDataEntityIdentifier,
  LabeledFile,
  ARCHIVE_TYPE_LABELS,
} from "@/common/store/dossier"
import { mdiPlus } from "@mdi/js"
import draggable from "vuedraggable"
import { getImageInformationForFile } from "@/common/lib/image"
import { omit } from "lodash"
import { extractWebInfo, withProtocol } from "@/common/lib/util"
import EditArchiveEntry from "@/components/edit/EditArchiveEntry.vue"
import { lookupDomain } from "@/common/data/domainList"
import { useI18n } from "vue-i18n"
const { t } = useI18n()
const props = withDefaults(
  defineProps<{
    disabled?: boolean
    entities: Set<EntityDataEntityIdentifier>
    id: string
  }>(),
  { disabled: false },
)

type ArchiveTabItem = {
  key: ArchiveEntryType
  value: string
  enabled: boolean
}

const hoverRow: Ref<string | number | null> = ref(null)
const draggedOn: Ref<boolean> = ref(false)
const uploader = ref()

const model = defineModel<ArchiveType>({ required: true })

const emit = defineEmits(["update:modelValue", "inUse", "notInUse"])

const currentType: Ref<string | null> = ref(null)

function deleteRow(i: any) {
  const tmp = [...model.value.entries]
  tmp.splice(i, 1)
  model.value.entries = tmp
}

async function onDrop(event: DragEvent) {
  draggedOn.value = false
  await addFiles(Array.from(event.dataTransfer!.files))
}

async function onInput(event: Event) {
  await addFiles(Array.from((event.target! as HTMLInputElement).files!))
}

function onMakeFeatured(targetId: string | undefined) {
  for (const entry of model.value.entries) {
    if (targetId && entry.id !== targetId) {
      if (
        entry.type == "document" ||
        entry.type == "website" ||
        entry.type == "press"
      ) {
        entry.featured = false
      }
    }
  }
}

async function handleWebsite(file: File, entry: LabeledFile) {
  const info = await extractWebInfo(file)
  Object.assign(entry, { ...omit(info, ["title"]), label: info?.title })
}

async function prepareFileEntry(file: File): Promise<ArchiveEntry|null> {
  if (file.size > 255 * 1024 * 1024) {
    // 255MiB in bytes
    alert(t("message.exceed_file_size", { file: file.name }))
    return null
  }

  const meta = {
    name: file?.name || undefined,
    mime: file?.type || undefined,
  } as Record<string, any>
  let type = "website"

  if (file.type?.startsWith("image/")) {
    Object.assign(meta, await getImageInformationForFile(file))
    type = "image"
  } else if (file.type?.startsWith("application/pdf")) {
    type = "document"
  }

  const entry = {
    title: meta?.name || "",
    type,
    isNew: true,
    files: [{ sourceBlock: null, meta, body: file }],
  } as ArchiveEntry

  if (file.type?.startsWith("text/html")) {
    const info = await extractWebInfo(file)
    Object.assign(entry, info)
    entry.type = "press"
  }

  if (entry.source) {
    try {
      const domainEntry = lookupDomain(new URL(withProtocol(entry.source)))
      if (domainEntry) {
        if (domainEntry.type === "social") {
          entry.type = "social"
        } else if (domainEntry.type === "press") {
          entry.type = "press"
        } else if (domainEntry.type === "company_db") {
          entry.type = "company_db"
        } else {
          entry.type = "website"
        }
      } else {
        entry.type = "website"
      }
    } catch (e) {
      // Ignore
    }
  }

  return entry
}

async function addFiles(files: File[]) {
  for (const file of files) {
    try {
      const entry = await prepareFileEntry(file)
      if (entry) {
        model.value.entries.unshift(entry)
      }
    } catch(e) {
      console.error(`Error ${e} while adding file ${file}`)
    }
  }
  model.value = {
    ...model.value,
  }
}

const availableTypes = computed(() => {
  return new Set(model.value.entries.map((x) => x.type))
})

const shouldShow = (item: ArchiveEntry) =>
  currentType.value === "all" || item.type === currentType.value

const tabList = computed(() => {
  return (Object.keys(ARCHIVE_TYPE_LABELS) as ArchiveEntryType[]).map(
    (key) =>
      ({
        key: key,
        value: ARCHIVE_TYPE_LABELS[key],
        enabled: availableTypes.value.has(key),
      }) as ArchiveTabItem,
  )
})

// FIXME Emit inuse/notinuse

watch(
  model,
  (newData) => {
    emit("update:modelValue", newData)
  },
  {
    deep: true,
  },
)
</script>
<style scoped lang="scss">
.draggedOnz {
  opacity: 0.5;
}
</style>
