<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" icon color="default" size="x-small">
        <v-icon :icon="mdiTranslate" />
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in supportedLocales"
        :key="index"
        :value="index"
        :active="($i18n.locale || '').startsWith(item)"
        @click="
          () => {
            $i18n.locale = item
            setLocale(item)
          }
        "
      >
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { mdiTranslate } from "@mdi/js"

const { t } = useI18n()
function setLocale(locale: string) {
  localStorage.setItem("selectedLanguage", locale)
}
const supportedLocales = ["en", "de"]
</script>
