<template>
  <v-table
    class="ma-0"
    :class="{ draggedOnz: draggedOn }"
    @dragover.prevent="draggedOn = true"
    @dragend.prevent="draggedOn = false"
    @dragleave.prevent="draggedOn = false"
    @drop.prevent="onDrop"
  >
    <thead>
      <tr>
        <th colspan="2" class="text-left">
          {{
            $t(
              ["gallery", "singleGallery"].includes(mode)
                ? "edit.file.preview"
                : "edit.file.file",
            )
          }}
        </th>
        <th class="text-left pl-2 ma-0" v-if="mode !== 'singleGallery'">
          {{ $t("edit.file.date") }}
        </th>
        <th class="text-left pl-2 ma-0">{{ $t("edit.file.label") }}</th>
        <th class="text-left pl-2 ma-0">{{ $t("edit.file.source") }}</th>
      </tr>
    </thead>
    <draggable
      tag="tbody"
      v-model="model"
      item-key="id"
      class="list-group"
      handle=".handle"
    >
      <template #item="{ element, index }">
        <tr
          :key="index"
          class="list-group-item"
          :class="{ 'highlight-row': hoverRow === index }"
          ref="rows"
        >
          <td class="shrink" style="width: 10%">
            <v-tooltip :text="$t('edit.rowDelete')">
              <template v-slot:activator="{ props }">
                <div class="d-flex handle cursor-move">
                  <v-btn
                    :icon="mdiDrag"
                    density="compact"
                    variant="plain"
                    :disabled="true"
                  />
                  <v-btn
                    :icon="mdiTrashCan"
                    density="compact"
                    variant="plain"
                    color="error"
                    :disabled="disabled"
                    @click="deleteRow(index)"
                    @mouseover="hoverRow = index"
                    @mouseleave="hoverRow = null"
                    v-bind="props"
                  />
                </div>
              </template>
            </v-tooltip>
          </td>
          <td
            class="pa-1 ma-1"
            style="width: 13%"
            v-if="['gallery', 'singleGallery'].includes(mode)"
          >
            <UseObjectUrl
              v-slot="url"
              :object="element.content.body"
              v-if="element.content.body"
            >
              <v-img :max-height="50" :src="url.value" />
            </UseObjectUrl>
            <v-img
              :max-height="50"
              :src="element.content.meta.thumbnail"
              v-else-if="element.content.meta.thumbnail"
            />
            <v-icon :icon="mdiImage" v-else size="50" />
          </td>
          <td :class="['pa-0', 'ma-0', 'shrink']" style="width: 30%" v-else>
            <template v-if="element.content.body">{{
              element.content.body.name
            }}</template>
            <template v-else-if="element.content.meta.name">{{
              element.content.meta.name
            }}</template>
            <template v-else>&hellip;</template>
          </td>
          <td
            :class="['pa-0', 'ma-0', 'shrink']"
            v-if="mode !== 'singleGallery'"
          >
            <date-input
              class="pa-0 ma-1"
              v-model="element.date"
              style="width: 8em"
              density="compact"
              variant="solo"
              :disabled="disabled"
              hide-details="auto"
              @update:model-value="updateCell(index, 'date', $event)"
              :id="id"
              :hideValidation="true"
            />
          </td>
          <td :class="['pa-0', 'ma-0', 'shrink']" style="width: 40%">
            <v-text-field
              class="pa-0 ma-1"
              v-model="element.label"
              density="compact"
              hide-details
              :disabled="disabled"
              @input="updateCell(index, 'label', $event.target.value)"
            />
          </td>
          <td :class="['pa-0', 'ma-0', 'shrink']" style="width: 50%">
            <v-text-field
              class="pa-0 ma-1"
              v-model="element.source"
              density="compact"
              hide-details
              :disabled="disabled"
              @input="updateCell(index, 'source', $event.target.value)"
            />
          </td>
        </tr>
      </template>
    </draggable>
    <tfoot>
      <tr
        v-if="
          mode !== 'singleGallery' ||
          (mode === 'singleGallery' && model.length === 0)
        "
      >
        <td class="shrink">
          <v-tooltip :text="$t('edit.fileAdd')">
            <template v-slot:activator="{ props }">
              <v-btn
                :icon="mdiPlus"
                density="compact"
                :disabled="disabled"
                @click="uploader.click()"
                v-bind="props"
              />
            </template>
          </v-tooltip>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            :accept="accept"
            @change="onInput"
          />
        </td>
      </tr>
    </tfoot>
  </v-table>
</template>

<script setup lang="ts">
import { ref, Ref } from "vue"
import { LabeledFile } from "@/common/store/dossier"
import { mdiDrag, mdiImage, mdiPlus, mdiTrashCan } from "@mdi/js"
import { UseObjectUrl } from "@vueuse/components"
import DateInput from "@/components/DateInput.vue"
import draggable from "vuedraggable"
import { getImageInformationForFile } from "@/common/lib/image"
import { omit } from "lodash"
import { extractWebInfo } from "@/common/lib/util"

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    mode?: "file" | "gallery" | "singleGallery" | "website"
    accept?: string
    id: string
  }>(),
  { disabled: false, mode: "file", accept: "*" },
)
const hoverRow: Ref<string | number | null> = ref(null)
const draggedOn: Ref<boolean> = ref(false)
const uploader = ref()

const model = defineModel<LabeledFile[]>({ required: true })

const updateCell = (
  rowIndex: number,
  key: keyof Omit<LabeledFile, "block" | "content">,
  value: string,
) => {
  const newData = [...model.value]
  newData[rowIndex][key] = value
  model.value = newData
}

function deleteRow(i: any) {
  const tmp = [...model.value]
  tmp.splice(i, 1)
  model.value = tmp
}

async function onDrop(event: DragEvent) {
  draggedOn.value = false
  await addFiles(Array.from(event.dataTransfer!.files))
}

async function onInput(event: Event) {
  await addFiles(Array.from((event.target! as HTMLInputElement).files!))
}

async function handleWebsite(file: File, entry: LabeledFile) {
  const info = await extractWebInfo(file)
  Object.assign(entry, { ...omit(info, ["title"]), label: info?.title })
}

async function addFiles(files: File[]) {
  for (const file of files) {
    const entry = {
      label: "",
      content: { sourceBlock: null, meta: {}, body: file },
    } as LabeledFile

    if (props.mode === "website") {
      await handleWebsite(file, entry)
    }

    if (file.name) {
      entry.content.meta.name = file.name
    }

    if (file.type?.startsWith("image/")) {
      Object.assign(entry.content.meta, await getImageInformationForFile(file))
    }

    model.value = [...model.value, entry]
  }
}
</script>
<style scoped lang="scss">
.draggedOnz {
  opacity: 0.5;
}
</style>
