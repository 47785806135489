<template>
  <v-text-field
    ref="dateInputElement"
    v-model="internal"
    :placeholder="$t('dateFormat.displayPlaceholder')"
    :rules="rules"
    validate-on="blur"
    :hide-details="hideDetails"
    @blur="onBlurInput"
    @keyup.enter="onBlurInput"
  >
  </v-text-field>
</template>

<script setup lang="ts">
import { computed, Ref, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import { VTextField } from "vuetify/components"
import { useErrorStore } from "@/common/store/error"
import { parseDate, toGermanDate, toIsoDate } from "@/common/lib/util"
import { DateString } from "@/common/store/dossier"

const { t } = useI18n()
const errorStore = useErrorStore()

const props = withDefaults(
  defineProps<{
    allowEmpty?: boolean
    id?: string
    hideValidation?: boolean
  }>(),
  {
    allowEmpty: false,
  },
)

const modelValue = defineModel<DateString | undefined>()
const internal = ref(undefined as string | undefined)

function onBlurInput() {
  const isFilled = internal.value && internal.value.length > 0
  const d = parseDate(internal.value) || undefined

  if (isFilled && !d) {
    /* Invalid date filled, do not update model */
  } else {
    const modelShouldBe = d && toIsoDate(d)
    if (modelValue.value !== modelShouldBe) {
      modelValue.value = modelShouldBe
    }
    const internalShouldBe = d && toGermanDate(d)
    if (internalShouldBe) {
      internal.value = internalShouldBe
    }
  }
}

watch(
  modelValue,
  (newVal) => {
    const d = parseDate(newVal)
    if (d) {
      internal.value = toGermanDate(d)
    } else {
      internal.value = undefined
    }
  },
  {
    flush: "sync",
    immediate: true,
  },
)

const dateInputElement: Ref<VTextField | null> = ref(null)

function acceptableDate(d: string): boolean {
  const parsed = parseDate(d)
  if (!parsed) {
    return true
  }
  if (parsed.day == "X" || parsed.month == "X") {
    return true
  }
  return !isNaN(new Date(toIsoDate(parsed)) as any)
}

const isEmpty = (val: string | undefined) => !(val && val.length > 0)

const rules = [
  (val: string) => props.allowEmpty || !isEmpty(val) || t("error.required"),
  (val: string) =>
    (props.allowEmpty && isEmpty(val)) ||
    !!parseDate(val) ||
    "Ungültiges Datumsformat",
  (val: string) => acceptableDate(val) || "Ungültiges Datum",
]

const hideDetails = computed(() => {
  return !dateInputElement.value || !dateInputElement.value?.errorMessages
})
</script>

<style scoped lang="scss"></style>
