<template>
  <tr class="list-group-item" :class="{ 'new-row': model.isNew }">
    <td>
      <v-tooltip :text="$t('edit.rowDelete')">
        <template v-slot:activator="{ props }">
          <div class="d-flex handle cursor-move">
            <v-btn
              :icon="mdiDrag"
              density="compact"
              variant="plain"
              :disabled="true"
            />
            <v-btn
              :icon="mdiTrashCan"
              density="compact"
              variant="plain"
              color="error"
              :disabled="disabled"
              @click="$emit('deleteRow')"
              @mouseover="$emit('deleteHover')"
              @mouseleave="$emit('deleteLeave')"
              v-bind="props"
            />
          </div>
        </template>
      </v-tooltip>
    </td>
    <td class="px-0 ma-0 shrink">
      <template v-if="model.type === 'image'">
        <UseObjectUrl
          v-if="model.files[0].body"
          v-slot="url"
          :object="model.files[0].body"
        >
          <v-img :max-height="64" :width="64" :src="url.value" />
        </UseObjectUrl>
        <v-img
          :max-height="64"
          :width="64"
          :src="model.files[0].meta.thumbnail"
          v-else-if="model.files[0].meta.thumbnail"
        />
        <v-icon :icon="mdiImage" v-else size="64" />
        <v-btn
          title="Featured"
          :icon="(model as any).featured ? mdiStar : mdiStarOutline"
          :color="(model as any).featured ? 'primary' : 'grey'"
          :variant="(model as any).featured ? 'flat' : 'text'"
          size="tiny"
          elevation="5"
          style="position: relative; top: -16px; left: 48px; z-index: 2"
          @click="(model as any).featured = !(model as any).featured"
        />
      </template>
      <template v-else>
        <div
          style="
            width: 64px;
            max-height: 96px;
            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
            font-size: 0.8em;
          "
          v-if="model.files[0].meta.name"
        >
          {{ model.files[0].meta.name }}
        </div>
        <v-icon v-else :icon="mdiFileDocument" size="64" />
        <v-btn
          title="Featured"
          :icon="
            (model as any).featured ? mdiStretchToPage : mdiStretchToPageOutline
          "
          :color="(model as any).featured ? 'primary' : 'grey'"
          :variant="(model as any).featured ? 'flat' : 'text'"
          size="tiny"
          elevation="5"
          style="
            position: relative;
            top: -16px;
            left: 48px;
            z-index: 2;
            border-radius: 0;
          "
          @click="
            ;(model as any).featured = !(model as any).featured
            ;(model as any).featured
              ? $emit('makeFeatured', (model as any).id)
              : 0
          "
        />
      </template>
    </td>
    <td>
      <v-container fluid>
        <v-row dense>
          <v-col cols="3">
            <v-select
              label="Typ"
              :items="typeItems"
              hide-details
              density="compact"
              color="primary"
              v-model="model.type"
              :readonly="typeItems.length < 2"
            />
          </v-col>
          <v-col class="d-flex" cols="9">
            <v-text-field
              variant="underlined"
              class="flex-grow-1"
              :label="titleLabel"
              density="compact"
              hide-details
              color="primary"
              v-model="model.title"
            />
            <v-text-field
              v-if="model.type === 'social'"
              variant="outlined"
              class="ml-2 flex-grow-0"
              style="width: 8em"
              label="Follower"
              density="compact"
              hide-details
              type="number"
              color="primary"
              v-model="(model as any).followers"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <v-select
              v-if="entities.size > 0"
              label="Person/Unternehmen"
              :items="entitiesItems"
              clearable
              hide-details
              density="compact"
              color="primary"
              multiple
              v-model="model.entities"
            >
              <template v-slot:item="{ props, item }">
                <v-list-item
                  v-bind="props"
                  :prepend-icon="item.raw.icon"
                ></v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col class="d-flex" cols="9">
            <v-text-field
              variant="underlined"
              class="flex-grow-1"
              label="URL"
              density="compact"
              hide-details
              color="primary"
              v-model="model.source"
            />
            <date-input
              v-if="['social'].includes(model.type)"
              variant="outlined"
              class="ml-2 flex-grow-0"
              style="width: 8em"
              label="Account angelegt"
              density="compact"
              color="primary"
              v-model="(model as any).established"
              :allow-empty="true"
            />
            <date-input
              v-else
              variant="outlined"
              class="ml-2 flex-grow-0"
              style="width: 8em"
              label="Datum"
              density="compact"
              color="primary"
              v-model="model.date"
              :allow-empty="true"
            />
            <!-- FIXME Featured heisst jetzt avatar -->
          </v-col>
        </v-row>
      </v-container>
    </td>
    <td>
      <v-checkbox-btn
        density="compact"
        color="primary"
        v-model="model.important"
      />
    </td>
    <td>
      <v-switch
        hide-details
        color="primary"
        density="compact"
        v-model="model.isNew"
      ></v-switch>
    </td>
    <td>
      <v-switch
        hide-details
        color="#3cb8a3"
        density="compact"
        v-model="model.analyze"
      ></v-switch>
    </td>
  </tr>
</template>

<script setup lang="ts">
import {
  ArchiveEntry,
  EntityDataEntityIdentifier,
  ARCHIVE_TYPE_LABELS,
} from "@/common/store/dossier"
import {
  mdiAccount,
  mdiDrag,
  mdiEye,
  mdiEyeOutline,
  mdiFileDocument,
  mdiImage,
  mdiStar,
  mdiStarOutline,
  mdiStretchToPage,
  mdiStretchToPageOutline,
  mdiTrashCan,
  mdiWarehouse,
} from "@mdi/js"
import { UseObjectUrl } from "@vueuse/components"
import { computed } from "vue"
import DateInput from "@/components/DateInput.vue"

const model = defineModel<ArchiveEntry>({ required: true })
const props = withDefaults(
  defineProps<{
    disabled?: boolean
    entities: Set<EntityDataEntityIdentifier>
  }>(),
  { disabled: false },
)
defineEmits([
  "update:model",
  "deleteRow",
  "deleteHover",
  "deleteLeave",
  "makeFeatured",
])

const allowedTypes = computed(() =>
  Object.keys(ARCHIVE_TYPE_LABELS).filter(
    (item) =>
      (item !== "image") !=
      model.value.files[0].meta?.mime?.startsWith("image/"),
  ),
)

const typeItems = computed(() =>
  Object.entries(ARCHIVE_TYPE_LABELS)
    .map(([value, label]) => ({ title: label, value }))
    .filter((item) => allowedTypes.value.includes(item.value)),
)

const titleLabel = computed(() =>
  ["image"].includes(model.value.type) ? "Beschriftung" : "Titel",
)

const entitiesItems = computed(() =>
  Array.from(props.entities)
    .sort()
    .map((value) => {
      if (value.startsWith("company/")) {
        return {
          title: value.split("/").slice(1).join("/"),
          value,
          icon: mdiWarehouse,
        }
      } else {
        return {
          title: value.split("/").slice(1).join("/"),
          value,
          icon: mdiAccount,
        }
      }
    }),
)
</script>

<style scoped lang="scss"></style>
