<template>
  <component v-if="innerComponent" :is="innerComponent" />
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia"
import { computed } from "vue"
import HandleLogin from "@/common/components/HandleLogin.vue"
import { useCryptoStore } from "@/common/store/crypto"
import { RouterView } from "vue-router"
import Default from "@/common/layouts/Default.vue"
const { ready } = storeToRefs(useCryptoStore())

const innerComponent = computed(() => (ready.value ? RouterView : HandleLogin))
</script>
