import { defineStore } from "pinia"

export const useErrorStore = defineStore("error", {
  state: () => ({
    errors: [{}] as any,
  }),
  actions: {
    addError(error: any) {
      this.errors.push(error)
    },
    removeError(id: string) {
      this.errors = this.errors.filter((error: any) => error.id !== id)
    },
  },
})
