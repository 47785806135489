<template>
  <div>
    <Teleport to="#app-bar-actions">
      <v-btn
        :prepend-icon="mdiAccountPlus"
        variant="outlined"
        rounded="xs"
        v-if="canCreateUser"
      >
        {{ $t("user.create") }}
        <create-user-dialog
          v-model="showUserCreateDialog"
          activator="parent"
          width="auto"
        />
      </v-btn>
    </Teleport>
    <manage-workstation-dialog v-model="manageWorkstationUser" width="auto" />
    <manage-credential-dialog v-model="manageCredentialUser" width="auto" />
    <v-table class="ma-4">
      <thead>
        <tr>
          <th class="shrink"></th>
          <th class="text-left">{{ $t("user.name") }}</th>
          <th class="text-left">{{ $t("user.type") }}</th>
          <th class="shrink"></th>
          <th class="shrink"></th>
        </tr>
      </thead>
      <tbody>
        <manage-user-line
          v-for="user in sortedUserList"
          :key="user.url"
          :user="user"
          @open-workstation-manager="manageWorkstationUser = user.url"
          @open-credential-manager="manageCredentialUser = user.url"
        />
      </tbody>
    </v-table>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import { mdiAccountPlus } from "@mdi/js"
import { asyncComputed, useSorted } from "@vueuse/core"
import { storeToRefs } from "pinia"
import { useUserStore } from "@/common/store/user"
import CreateUserDialog from "@/components/CreateUserDialog.vue"
import { ApiUrl } from "@/common/lib/types"
import ManageWorkstationDialog from "@/components/ManageWorkstationDialog.vue"
import ManageUserLine from "@/components/ManageUserLine.vue"
import ManageCredentialDialog from "@/components/ManageCredentialDialog.vue"
const { currentUser, users } = storeToRefs(useUserStore())

const userStore = useUserStore()
onMounted(userStore.fetchUsers)

const canCreateUser = asyncComputed(async () => {
  return currentUser.value && ["superuser"].includes(currentUser.value.type)
}, false)
const showUserCreateDialog = ref(false)
const manageWorkstationUser = ref(null as ApiUrl | null)
const manageCredentialUser = ref(null as ApiUrl | null)

const sortedUserList = useSorted(users, (a, b) => {
  if (a.url === currentUser.value?.url) {
    return -1
  }
  if (b.url === currentUser.value?.url) {
    return 1
  }
  const ret1 = a.display_name.localeCompare(b.display_name)
  if (ret1 !== 0) {
    return ret1
  }
  return a.url.localeCompare(b.url)
})
</script>
<style scoped lang="scss"></style>
