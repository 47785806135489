<template>
  <v-table class="ma-0">
    <thead>
      <tr>
        <th class="shrink" />
        <th
          class="text-left pa-0 ma-0"
          v-for="(title, i) in columnTitles"
          :key="i"
          v-text="title"
        />
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="(row, index) in internalTableData"
        :key="index"
        :class="{ 'highlight-row': hoverRow === index }"
      >
        <td class="shrink">
          <v-tooltip :text="$t('edit.rowDelete')">
            <template v-slot:activator="{ props }">
              <v-btn
                :icon="mdiTrashCan"
                density="compact"
                variant="plain"
                color="error"
                :disabled="disabled"
                @click="deleteRow(index)"
                @mouseover="hoverRow = index"
                @mouseleave="hoverRow = null"
                v-bind="props"
              />
            </template>
          </v-tooltip>
        </td>
        <td
          v-for="(_, cellIndex) in row"
          :key="cellIndex"
          class="pa-0 pt-2 ma-0"
          :style="{
            width:
              isYearCell(cellIndex) || isFollowerCountCell(cellIndex)
                ? '160px'
                : undefined,
          }"
        >
          <text-input
            class="pa-0 ma-1"
            v-model="row[cellIndex]"
            density="compact"
            :disabled="disabled"
            :type="
              isYearCell(cellIndex) || isFollowerCountCell(cellIndex)
                ? 'number'
                : 'text'
            "
            :placeholder="isYearCell(cellIndex) ? 'z. B. 2023' : undefined"
            :min="isFollowerCountCell(cellIndex) ? 0 : undefined"
            :rules="
              isUrlCell(cellIndex)
                ? [urlValidator]
                : isYearCell(cellIndex)
                ? [yearValidator]
                : []
            "
            validate-on="blur"
            @input="updateCell(index, cellIndex, $event.target.value)"
          />
        </td>
      </tr>
      <tr>
        <td class="shrink">
          <v-tooltip :text="$t('edit.rowAdd')">
            <template v-slot:activator="{ props }">
              <v-btn
                :icon="mdiPlus"
                density="compact"
                :disabled="disabled"
                @click="addRow"
                v-bind="props"
              />
            </template>
          </v-tooltip>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from "vue"
import { SocialMediaType } from "@/common/store/dossier"
import { mdiPlus, mdiTrashCan } from "@mdi/js"
import urlRegex from "url-regex"
import TextInput from "@/components/TextInput.vue"

const props = withDefaults(
  defineProps<{
    modelValue: SocialMediaType[]
    disabled?: boolean
    id: string
  }>(),
  { disabled: false },
)

const emit = defineEmits(["update:modelValue"])
const hoverRow: Ref<string | number | null> = ref(null)

const initializedData = ref(
  props.modelValue.map((item) =>
    item.length === 4
      ? item
      : [
          // Quelle (source) -> URL
          item[2] || "",
          // Inhalt (content)
          item[1] || "",
          // Eintrittsdatum (registration date)
          "",
          undefined,
        ],
  ),
)

const internalTableData = computed({
  get: () => initializedData.value,
  set: (value) => {
    initializedData.value = value
    emit("update:modelValue", value)
  },
})

const columnTitles = [
  "URL",
  "Inhalt",
  "Eintrittsdatum",
  "Anzahl Follower",
] as const

const updateCell = (rowIndex: number, cellIndex: number, value: string) => {
  const newData = [...internalTableData.value]
  newData[rowIndex][cellIndex] = value
  internalTableData.value = newData
}

const addRow = () => {
  internalTableData.value = [
    ...internalTableData.value,
    ["", "", "", undefined],
  ]
}

function deleteRow(i: any) {
  const tmp = [...internalTableData.value]
  tmp.splice(i, 1)
  internalTableData.value = tmp
}

const urlValidator = (value: string) => {
  return urlRegex({ exact: true, strict: false }).test(value) || "Ungültige URL"
}

const yearValidator = (value: string) => {
  return /^\d{4}$/.test(value) || "Das Jahr muss 4 Ziffern haben"
}

const isUrlCell = (cellIndex: number) => {
  return cellIndex === 0
}

const isContentCell = (cellIndex: number) => {
  return cellIndex === 1
}

const isYearCell = (cellIndex: number) => {
  return cellIndex === 2
}

const isFollowerCountCell = (cellIndex: number) => {
  return cellIndex === 3
}
</script>

<style scoped lang="scss"></style>
