<script lang="ts" setup>
import {
  ChapterType,
  DossierContentSection,
  EntityDataEntityIdentifier,
  EntityDataEntityTypes,
} from "@/common/store/dossier/types"
import {
  mdiEyeOff,
  mdiHelpBox,
  mdiPlus,
  mdiAccount,
  mdiWarehouse,
} from "@mdi/js"
import { computed } from "vue"
import { isSmartChapter } from "@/common/lib/util"
import { String } from "lodash"
const prop = defineProps<{ rows: DossierContentSection[][] }>()
const CHAPTERS = [
  "Text",
  "RichText",
  "EntityData",
  "KeyValueTable",
  "CommentsBlock",
  "NetworkAnalysis",
  "Chronology",
  "KpiBarChart",
  "SocialMedia",
  "FeaturedPhoto",
  "CompanyInformation",
  "EntityData",
  "Archive",
  "NetworkAnalysis",
  "Gallery",
  "FileCollection",
  "FeaturedPhoto",
  "SavedWebsite",
  "Archive",
  "ExternalWidgets",
]
function getIcon(headline: string) {
  switch (headline) {
    case "Eingabedaten":
    case "Aufgaben":
      return mdiEyeOff
    case "Kommentare":
      return mdiHelpBox
    default:
      return undefined
  }
}

type sidebarType = {
  type: ChapterType
  icon?: string
  order?: number
  group?: string
  img?: string
  title: string
  to: string
}

// Sidebar item order
const sidebar = computed(() => {
  const list: sidebarType[] = []
  const listGroup: { [key: string]: sidebarType[] } = {}

  prop.rows.forEach((row, rowIndex) => {
    row.forEach((item, itemIndex) => {
      const { type, meta } = item.content

      if (meta.headline && CHAPTERS.includes(type)) {
        if (type === "CommentsBlock") return

        let groupName: EntityDataEntityTypes | null = null

        if (type === "EntityData") {
          const entity = (item.content.body as any)
            ?.entity as EntityDataEntityIdentifier

          groupName = entity.split("/", 2)[0] as EntityDataEntityTypes
        }

        let icon = getIcon(meta.headline)

        let img = isSmartChapter(type)
          ? "custom:smartBlockIcon"
          : "custom:standardBlockIcon"

        if (groupName) {
          listGroup[groupName] = listGroup[groupName] || []
          listGroup[groupName].push({
            type,
            icon: groupName === "person" ? mdiAccount : mdiWarehouse,
            group: groupName,
            title: meta.headline,
            to: `#s${rowIndex},${itemIndex}`,
          })
        } else {
          list.push({
            type,
            icon,
            order: meta.order,
            img: icon ?? img,
            title: type === "ExternalWidgets" ? "Google Trends" : meta.headline,
            to: `#s${rowIndex},${itemIndex}`,
          })
        }
      }
    })
  })

  return { list, listGroup }
})
</script>

<template>
  <v-list density="compact" nav class="d-flex flex-column">
    <v-list-item :nav="true" density="compact" style="order: -1"
      ><h3 class="mt-4">Schnellzugriff</h3></v-list-item
    >
    <template v-for="(group, key) in sidebar.listGroup" :key="key">
      <v-list-group :value="key">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            :prepend-icon="group[0].icon ?? mdiAccount"
            :title="$t('sidebarGroup.' + key)"
          ></v-list-item>
        </template>
        <v-list-item
          v-for="({ title, to }, i) in group"
          :key="i + to"
          :title="title"
          :value="title"
          :to="to"
          :active="false"
        ></v-list-item>
      </v-list-group>
    </template>
    <v-list-item
      v-for="({ type, icon, img, title, to, order }, j) in sidebar.list"
      :key="j"
      nav
      :style="{ order: order }"
      density="compact"
      :active="type === 'Archive' ? true : false"
      :to="to"
    >
      <template #prepend>
        <span>
          <v-icon v-if="icon" class="mr-2" :icon="icon" />
          <v-icon v-else :icon="img" class="mr-2" />
        </span>
      </template>
      <template #title>
        {{ title }}
      </template>
    </v-list-item>

    <v-list-item
      density="compact"
      nav
      :active="false"
      to="#block-adder-bottom"
      style="order: 131"
      ><v-icon class="mr-2" :icon="mdiPlus" />Abschnitt hinzufügen</v-list-item
    >

    <!--<v-list-item :prepend-icon="mdiLock" :title="$t('lockWorkstation')" @click="lockWorkstation" :disabled="true"/>-->
  </v-list>
</template>
