import { createRouter, createWebHistory } from "vue-router"

import Landing from "@/views/Landing.vue"
import { defineComponent } from "vue"
import LoginRequired from "@/layouts/LoginRequired.vue"
import DossierSelect from "@/views/DossierSelect.vue"
import DossierEdit from "@/views/DossierEdit.vue"
import UserList from "@/views/UserList.vue"
import WorkstationLocked from "@/layouts/WorkstationLocked.vue"
import Default from "@/common/layouts/Default.vue"
import DossierView from "@/common/views/DossierView/index.vue"

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/_internal_login_UNUSED",
      component: LoginRequired,
      children: [
        {
          component: Default,
          path: "/",
          children: [
            {
              path: "",
              name: "home",
              component: Landing,
              meta: {
                titleKey: "home",
              },
            },
            {
              path: "/ws/:accessPointId/",
              name: "login",
              component: defineComponent({
                render: () => "Access point loaded",
              }),
            },
            {
              path: "dossiers",
              children: [
                {
                  path: "",
                  name: "dossierSelect",
                  component: DossierSelect,
                  meta: {
                    titleKey: "dossier.overview",
                  },
                },
                {
                  path: ":dossierId",
                  name: "dossier",
                  component: DossierEdit,
                  props: (route) => ({ dossierId: route.params.dossierId }),
                  meta: {
                    titleKey: null,
                  },
                },
              ],
            },
            {
              path: "users",
              name: "userList",
              component: UserList,
            },
          ],
        },
        {
          path: "/dossiers/:dossierId/preview",
          name: "dossierPreview",
          component: DossierView,
          props: (route) => ({
            dossierId: route.params.dossierId,
            preview: true,
          }),
        },
      ],
    },
    {
      path: "/_",
      children: [
        {
          path: "locked",
          name: "workstationLocked",
          component: WorkstationLocked,
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && to.hash.length < 22) {
      return {
        el: to.hash,
        behavior: "smooth",
        top: 150,
      }
    }
  },
})

export default router
